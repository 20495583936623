/** @format */

import React, { useEffect, useState } from "react";
import { fetchAllSubscriptions } from "../../apis/subscriptionApis";
import Spinner from "../Function/Spinner";

const ViewZohoSubscription = () => {
	const [data, setData] = useState([{}]);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		const fetchPlan = async () => {
			setLoading(true);
			const response = await fetchAllSubscriptions();
			setData(response?.data?.data?.plans);
			setLoading(false);
		};
		fetchPlan();
	}, []);

	if (loading) {
		return loading && <Spinner />;
	}

	return (
		<div style={{ padding: "0 4%", marginTop: "4vh" }}>
			<div>
				<h3 style={{ textAlign: "center", marginTop: "20px", marginBottom: "20px", fontWeight: "25px", fontWeight: "700" }}>All Zoho Subscription Plans</h3>
			    <div className='table-container1' style={{ marginTop: '20px' ,height:'600px'}}>
        <table className="table table-sm table-bordered">
						<thead>
							<tr>
								<th style={{ fontSize: "20px" }}>SNo.</th>
								<th
									style={{ fontSize: "20px", padding: "10px", margin: "10px" }}>
									Name
								</th>
								<th
									style={{ fontSize: "20px", padding: "10px", margin: "10px" }}>
									Plan Code
								</th>
								<th
									style={{ fontSize: "20px", padding: "10px", margin: "10px" }}>
									Recurring Price
								</th>
								<th
									style={{ fontSize: "20px", padding: "10px", margin: "10px" }}>
									Interval
								</th>
								<th
									style={{ fontSize: "20px", padding: "10px", margin: "10px" }}>
									Interval Unit
								</th>
								<th
									style={{ fontSize: "20px", padding: "10px", margin: "10px" }}>
									Description
								</th>
							</tr>
						</thead>
						<tbody>
							{data.map((plan, index) => (
								<tr key={index}>
									<td
										style={{
											fontSize: "1rem",
											fontWeight: "400",
											maxWidth: "100px",
											padding: "10px",
											margin: "10px",
										}}>
										{index + 1}
									</td>
									<td
										style={{
											fontSize: "1rem",
											fontWeight: "400",
											minWidth: "300px",
											padding: "10px",
											margin: "10px",
										}}>
										{plan.name}
									</td>
									<td
										style={{
											fontSize: "1rem",
											fontWeight: "400",
											minWidth: "150px",
											padding: "10px",
											margin: "10px",
										}}>
										{plan.plan_code}
									</td>
									<td
										style={{
											fontSize: "1rem",
											fontWeight: "400",
											minWidth: "150px",
											padding: "10px",
											margin: "10px",
										}}>
										{plan.recurring_price}
									</td>
									<td
										style={{
											fontSize: "1rem",
											fontWeight: "400",
											minWidth: "150px",
											padding: "10px",
											margin: "10px",
										}}>
										{plan.interval}
									</td>
									<td
										style={{
											fontSize: "1rem",
											fontWeight: "400",
											minWidth: "150px",
											padding: "10px",
											margin: "10px",
										}}>
										{plan.interval_unit}
									</td>
									<td
										style={{
											fontSize: "1rem",
											fontWeight: "400",
											minWidth: "300px",
											padding: "10px",
                      margin: "10px",
                      wordWrap: "break-word",
                      wordBreak: "break-word",
                      overflowWrap: "break-word"
										}}>
										{plan.description || "N/A"}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default ViewZohoSubscription;
