import { Box, Button, Modal } from "@mui/material";
import React from "react";

export default function GateWayDetails({ data }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  console.log("Data - ", data);
  return (
    <div>
      <button
        className="btn btn-primary"
        onClick={handleOpen}
        variant="contained"
      >
        Gateway
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <table>
            <thead>
              <tr>
                <th>Gateway</th>
                <th>Amount</th>
                <th>Gateway Id</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {data?.map((d) => (
                  <React.Fragment key={d.gatewayId}>
                    <th>{d.gatewayName}</th>
                    <th>{d.amount}</th>
                    <th>{d.gatewayId}</th>
                  </React.Fragment>
                ))}
              </tr>
            </tbody>
          </table>
        </Box>
      </Modal>
    </div>
  );
}
