export const INDIAN_STATES = {
  "Andhra Pradesh": { code: "AP", name: "Andhra Pradesh" },
  "Arunachal Pradesh": { code: "AR", name: "Arunachal Pradesh" },
  Assam: { code: "AS", name: "Assam" },
  Bihar: { code: "BR", name: "Bihar" },
  Chhattisgarh: { code: "CG", name: "Chhattisgarh" },
  Goa: { code: "GA", name: "Goa" },
  Gujarat: { code: "GJ", name: "Gujarat" },
  Haryana: { code: "HR", name: "Haryana" },
  "Himachal Pradesh": { code: "HP", name: "Himachal Pradesh" },
  Jharkhand: { code: "JH", name: "Jharkhand" },
  Karnataka: { code: "KA", name: "Karnataka" },
  Kerala: { code: "KL", name: "Kerala" },
  "Madhya Pradesh": { code: "MP", name: "Madhya Pradesh" },
  Maharashtra: { code: "MH", name: "Maharashtra" },
  Manipur: { code: "MN", name: "Manipur" },
  Meghalaya: { code: "ML", name: "Meghalaya" },
  Mizoram: { code: "MZ", name: "Mizoram" },
  Nagaland: { code: "NL", name: "Nagaland" },
  Odisha: { code: "OD", name: "Odisha" },
  Punjab: { code: "PB", name: "Punjab" },
  Rajasthan: { code: "RJ", name: "Rajasthan" },
  Sikkim: { code: "SK", name: "Sikkim" },
  "Tamil Nadu": { code: "TN", name: "Tamil Nadu" },
  Telangana: { code: "TG", name: "Telangana" },
  Tripura: { code: "TR", name: "Tripura" },
  "Uttar Pradesh": { code: "UP", name: "Uttar Pradesh" },
  Uttarakhand: { code: "UK", name: "Uttarakhand" },
  "West Bengal": { code: "WB", name: "West Bengal" },
  "Andaman and Nicobar Islands": {
    code: "AN",
    name: "Andaman and Nicobar Islands",
  },
  Chandigarh: { code: "CH", name: "Chandigarh" },
  "Dadra and Nagar Haveli and Daman and Diu": {
    code: "DN",
    name: "Dadra and Nagar Haveli and Daman and Diu",
  },
  Lakshadweep: { code: "LD", name: "Lakshadweep" },
  Delhi: { code: "DL", name: "Delhi" },
  Puducherry: { code: "PY", name: "Puducherry" },
  Ladakh: { code: "LA", name: "Ladakh" },
  "Jammu and Kashmir": { code: "JK", name: "Jammu and Kashmir" },
};

export const INDIANSTATES = [
  { value: "Andhra Pradesh", label: "Andhra Pradesh" },
  { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
  { value: "Assam", label: "Assam" },
  { value: "Bihar", label: "Bihar" },
  { value: "Chhattisgarh", label: "Chhattisgarh" },
  { value: "Goa", label: "Goa" },
  { value: "Gujarat", label: "Gujarat" },
  { value: "Haryana", label: "Haryana" },
  { value: "Himachal Pradesh", label: "Himachal Pradesh" },
  { value: "Jharkhand", label: "Jharkhand" },
  { value: "Karnataka", label: "Karnataka" },
  { value: "Kerala", label: "Kerala" },
  { value: "Madhya Pradesh", label: "Madhya Pradesh" },
  { value: "Maharashtra", label: "Maharashtra" },
  { value: "Manipur", label: "Manipur" },
  { value: "Meghalaya", label: "Meghalaya" },
  { value: "Mizoram", label: "Mizoram" },
  { value: "Nagaland", label: "Nagaland" },
  { value: "Odisha", label: "Odisha" },
  { value: "Punjab", label: "Punjab" },
  { value: "Rajasthan", label: "Rajasthan" },
  { value: "Sikkim", label: "Sikkim" },
  { value: "Tamil Nadu", label: "Tamil Nadu" },
  { value: "Telangana", label: "Telangana" },
  { value: "Tripura", label: "Tripura" },
  { value: "Uttar Pradesh", label: "Uttar Pradesh" },
  { value: "Uttarakhand", label: "Uttarakhand" },
  { value: "West Bengal", label: "West Bengal" },
  // Union Territories
  {
    value: "Andaman and Nicobar Islands",
    label: "Andaman and Nicobar Islands",
  },
  { value: "Chandigarh", label: "Chandigarh" },
  {
    value: "Dadra and Nagar Haveli and Daman and Diu",
    label: "Dadra and Nagar Haveli and Daman and Diu",
  },
  { value: "Lakshadweep", label: "Lakshadweep" },
  { value: "Delhi", label: "Delhi" },
  { value: "Puducherry", label: "Puducherry" },
  { value: "Ladakh", label: "Ladakh" },
  { value: "Lakshadweep", label: "Lakshadweep" },
  { value: "Jammu and Kashmir", label: "Jammu and Kashmir" },
  { value: "Ladakh", label: "Ladakh" },
];
