import axios from "axios";
import React, { useRef } from "react";
import { serverUrl } from "../ApiUrl";
import { Button } from "@mui/material";

export default function SearchDataWithStage({ stage, setDisplayData }) {
  let search = useRef("");
  const result = (name) => {
    axios
      .post(`${serverUrl.u}/api/tele/searchSingleSubscription`, {
        name: name,
        subscriptionStage: stage,
      })
      .then(function (res) {
        setDisplayData(res.data.alisteSubscription);
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <input
        type="text"
        style={{ float: "right" }}
        ref={search}
        placeholder="Search here by name...."
      />
      <Button
        variant="contained"
        style={{
          borderRadius: "12px",
          height: "34px",
          position: "absolute",
          right: "1%",
          top: "0%",
        }}
        onClick={() => result(search?.current?.value)}
      >
        Search
      </Button>
    </div>
  );
}
