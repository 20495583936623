import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import FileSaver from "file-saver";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import ErrorMessage from "./components/ErrorMessage";
import React, { useEffect, useRef, useState } from "react";
import { CitySelect, StateSelect } from "react-country-state-city";

import { notify, notifyFailed } from "../../component/Common/Notification";
import {
  Cached,
  ContentPaste,
  DeleteForever,
  Refresh,
} from "@mui/icons-material";
import { apiRequest } from "../../utils";
import { serverUrl } from "../../component/ApiUrl";
import {
  fetchAllItems,
  searchClient,
} from "../../component/Client registation/Api";

import ReactSelect from "react-select";

import "./performa.scss";
import { useSelector } from "react-redux";
import SmartMeterDetailsModal from "./components/SmartMeterDetailsModal";
import { daysBetween } from "../../utilities/dateTime";
import { MonthSelector } from "../../component/Common/MonthSelector";
import moment from "moment";
import { INDIANSTATES } from "../../constants";

function getFirstAndLastDateOfMonth(month) {
  // Get the current year
  const currentYear = new Date().getFullYear();

  // First day of the month
  const firstDate = new Date(currentYear, month, 1);

  // Last day of the month
  const lastDate = new Date(currentYear, month + 1, 0); // The "0" day of the next month gives the last day of the current month

  return {
    firstDate: firstDate,
    lastDate: lastDate,
  };
}

export default function CreatePerforma({ setTab, data }) {
  let user = useSelector((state) => state.sar.data.user);

  const [customers, setCustomers] = useState([]);

  // Modal State
  const [open, setOpen] = useState(false);
  const [showClient, setShowClient] = useState(false);
  // Items State
  const [displayItems, setDisplayItems] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [smartMeterBillingDetails, setSmartMeterBillingDetails] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [propertiesItemData, setPropertiesItemData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  // Client Data
  const [clientsData, setClientsData] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [propertyData, setPropertyData] = useState([]);

  const [customerData, setCustomerData] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({
    first_name: "Select",
    last_name: "Customer",
  });

  const [paymentLink, setPaymentLink] = useState("");

  const [invoiceTypes, setInvoiceTypes] = useState([]);

  // DROPDOWN INPUTS

  // REFS
  const searchCustomerDropdownRef = useRef(null);

  useEffect(() => {
    if (searchQuery.length < 3) return;

    const fetchData = async () => {
      await fetchCustomerData(searchQuery);
    };

    fetchData();
  }, [searchQuery]);

  async function fetchCustomerData(query, visible = true) {
    const response = await searchClient(query);

    if (!response.success) {
      notifyFailed(response.message);
      return;
    }

    setCustomerData(response?.data?.customers);
    setIsDropdownVisible(visible);
  }

  const handleCustomerSearch = (customer) => {
    setSelectedCustomer(customer);
    setCustomerData([]);
    setValue("name", `${customer.first_name} ${customer.last_name}`);
    setValue("billTo.name", `${customer.first_name} ${customer.last_name}`);
    setValue("shipTo.name", `${customer.first_name} ${customer.last_name}`);
  };

  // Fetch all items && all clients
  useEffect(() => {
    // Fetch all items
    const fetchData = async () => {
      const response = await fetchAllItems();
      if (!response.success) {
        notifyFailed("Failed to load Items");
      }

      // Set State
      if (response.success) {
        setDisplayItems(
          response.data.items.sort((a, b) => a.name.localeCompare(b.name))
        );
      }
      const clientResponse = await apiRequest({
        url: `${serverUrl.smartMeter}/client/fetch`,
        method: "GET",
      });

      if (!clientResponse.success) {
        notifyFailed("Failed to load Client Data");
      }

      if (clientResponse.success) {
        setClientsData(clientResponse.data?.data);
      }
    };

    fetchData();
  }, []);

  // Global events to close search customer dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchCustomerDropdownRef.current &&
        !searchCustomerDropdownRef.current.contains(event.target) &&
        isDropdownVisible
      ) {
        // setCustomerData([]);
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Clean up event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Fetch Client Properties
  useEffect(() => {
    const fetchData = async () => {
      if (selectedClient === "") {
        notifyFailed("Please first select the Client first!");
        return;
      }

      const response = await apiRequest({
        url: `${serverUrl.smartMeter}/property/fetch?clientId=${selectedClient}&owner=client`,
        method: "GET",
      });

      if (!response.success) {
        notifyFailed(`Failed to Fetch Properties\n${response.message}`);
        return;
      }

      setPropertyData(response?.data?.data?.property);
    };

    fetchData();
  }, [selectedClient]);

  // Fetch Invoice Types
  useEffect(() => {
    const fetchInvoiceType = async () => {
      const response = await apiRequest({
        url: `${serverUrl.u}/v3/invoiceSeries/list`,
        method: "GET",
      });

      if (!response.success) {
        notifyFailed(`Failed to load Invoice data\n ${response.message}`);
      }
      setInvoiceTypes(response?.data?.data?.invoiceSeries);
    };
    fetchInvoiceType();
  }, []);

  const {
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues:
      Object.keys(data).length > 0
        ? data.rawData
        : {
            autoFill: false,
            subTotal: 0,
            amount: 0,
            tax: 0.0,
            invoiceType: "",
            paymentLinkAmount: 0,
            generatedHex: "",
            payments: [],
            clientId: { value: "", label: "" },
            properties: [],
            roundOfValue: 0,
            billTo: {
              name: "",
              address: "",
              city: "",
              state: "",
              country: "India",
              pinCode: "",
              gstNumber: "",
              _id: "",
            },
            shipTo: {
              name: "",
              address: "",
              city: "",
              state: "",
              country: "India",
              pinCode: "",
              gstNumber: "",
              _id: "",
            },
            items: [
              {
                itemId: "",
                name: "",
                code: "",
                details: "",
                hsnCode: "",
                qty: 1,
                rate: 0,
                mrp: 0,
                cgst: 0,
                igst: 0,
                sgst: 0,
                devices: "",
                subTotal: 0,
                total: 0,
                discount: 0,
                type: "",
                propertyId: "",
                billingPeriod: {
                  startDate: "",
                  endDate: "",
                },
              },
            ],
          },
  });

  async function duplicatePI() {
    if (Object.keys(data).length !== 0) {
      // fetch Customer
      setSearchQuery(data.rawData.name);
      await fetchCustomerData(data.rawData.name, false);

      // set Selected Customer
      setSelectedCustomer(
        customerData.find(
          (customer) =>
            `${customer.first_name} ${customer.last_name}` === data.rawData.name
        )
      );

      // Select Invoice Type
      const selectedInvoiceType = invoiceTypes.find(
        (invoice) => invoice._id === data.rawData.invoiceType
      );
      if (selectedInvoiceType) {
        setValue("invoiceType", {
          value: selectedInvoiceType._id,
          label: selectedInvoiceType.name,
        });
      }

      // Set Same as Bill to True
      setValue("autoFill", true);

      // set Selected month
      // Here, we read the first item & get the billing period from which we find the month
      if (selectedInvoiceType) {
        if (selectedInvoiceType.name === "Smart meter invoice") {
          const date = getValues("items.0.billingPeriod.startDate");

          if (date) {
            const month = new Date(date).getMonth();
            setSelectedMonth(month);
          }
        }
      }

      // Set client
      if (clientsData.length > 0) {
        setSelectedClient(getValues("clientId"));
      }
    }
  }

  useEffect(() => {
    duplicatePI();
  }, [data, clientsData]);

  // This effect runs whenever `data` changes
  useEffect(() => {
    if (propertiesItemData && propertiesItemData.length > 0) {
      // Reset or initialize your product field
      setValue("items", []);

      let index = 0;
      // Set values based on the new data
      propertiesItemData.forEach((item) => {
        const updatedProducts = item ? Object.values(item) : [];

        for (const product of updatedProducts) {
          setValue(`items.${index}`, product);
          index++;
        }
      });
    }
    calculateTotal();
  }, [propertiesItemData, setValue]);

  const {
    fields: itemFields,
    append: appendItem,
    remove: removeItem,
  } = useFieldArray({
    control,
    name: "items",
  });

  function handleCustomerSelect(e) {
    const customer = JSON.parse(e.target.value);
    setSelectedCustomer(customer);
  }

  async function refreshItemsList() {
    const response = await fetchAllItems();
    if (!response.success) {
      notifyFailed("Failed to load Items");
    }

    // Set State
    if (response.success) {
      setDisplayItems(
        response.data.items.sort((a, b) => a.name.localeCompare(b.name))
      );

      notify("Items list updated successfully!!");
    }
  }
  async function onSubmit(data) {
    let finalData = data;
    delete data.autoFill;
    finalData.customerId = selectedCustomer._id;
    finalData.createdBy = user._id;
    // looping through products to set devices array
    data.items.forEach((items, index) => {
      finalData.items[index].devices = Array.isArray(items.devices)
        ? items.devices
        : items.devices.split(",");

      if (
        finalData.items[index].devices.length > 1 &&
        Number(finalData.items[index].qty) !==
          finalData.items[index].devices.length
      ) {
        notifyFailed(
          `The Device IDs and quantity for ${finalData.items[index].name} do not match!!`
        );
        return;
      }
    });

    finalData.clientId = data.clientId || "";
    finalData.properties = data.properties
      ? [data.properties].map((p) => `${p.value}`)
      : [""];

    finalData.invoiceType = data.invoiceType?.value || "";

    // Convert Client object to value
    if (typeof finalData.clientId === "object") {
      finalData.clientId = finalData.clientId.value;
    }

    console.log("FinalDATA", finalData);

    delete finalData._id;

    const response = await apiRequest({
      url: `${serverUrl.u}/v3/performa/create`,
      method: "POST",
      data: finalData,
    });

    if (!response.success) {
      notifyFailed(response.message);
      return;
    }

    setPaymentLink(response.data?.data?.performa?.payments[0]?.short_url);
    notify(response.message);

    const createPdf = await apiRequest({
      url: `${serverUrl.u}/api/tele/makePIPdf`,
      method: "POST",
      data: {
        ...finalData,
        piNumber: response.data?.data.performa.piNumber,
      },
    });

    if (!createPdf.success) {
      notifyFailed("Failed to create PDF");
      notifyFailed(createPdf.message);
      return;
    }

    notify("Please wait 2 seconds to generate pdf");
    setTimeout(() => {
      FileSaver.saveAs(
        `${serverUrl.u + createPdf.data.urlObj[0].substring(6)}`,
        `${finalData.name}.pdf`
      );

      // setTab("performa");
    }, 2000);
  }

  async function handleAddProperties(data) {
    setValue("product", []);

    const finalData = [];

    for (const property of data) {
      const response = await apiRequest({
        url: `${serverUrl.smartMeter}/proformaInvoice/test`,
        method: "POST",
        data: {
          month: selectedMonth,
          propertyId: property.value,
        },
      });

      if (!response.success) {
        notifyFailed(response.message);
        return;
      }

      // Add different Products
      const itemData = response.data?.data?.proformaItems;
      // const updatedProducts = itemData ? Object.values(itemData) : [];

      finalData.push(itemData);
      // for (const item of updatedProducts) {
      //     setValue(`product.${index}`, item);
      //     index++;
      // }
    }
    setPropertiesItemData(finalData);
  }

  function handleShipToAutofill(autoFill) {
    if (autoFill) {
      const billToValues = getValues("billTo");

      Object.keys(billToValues).forEach((key) => {
        setValue(`shipTo.${key}`, billToValues[key]);
      });
    } else {
      // Clear ship to fields if checkbox is unchecked
      Object.keys(getValues("shipTo")).forEach((key) => {
        setValue(`shipTo.${key}`, "");
      });
    }
  }

  function handleBillToSelectAddress(e) {
    console.log("BillTo Select", e.value);
    const addr = e.value;
    [
      "address",
      "city",
      "state",
      "gstNumber",
      "pinCode",
      "country",
      "_id",
    ].forEach((key) => setValue(`billTo.${key}`, addr[key]));
  }

  function handleShipToSelectAddress(e) {
    const addr = e.value;

    [
      "address",
      "city",
      "state",
      "gstNumber",
      "pinCode",
      "country",
      "_id",
    ].forEach((key) => setValue(`shipTo.${key}`, addr[key]));
  }

  //
  function onSelectItem(data) {
    const { index, item } = data.value;

    const keys = ["name", "code", "cgst", "igst", "sgst", "hsnCode", "type"];
    for (const key of keys) {
      setValue(`items.${index}.${key}`, item[key]);
    }
    setValue(`items.${index}.details`, item["description"]);
    setValue(`items.${index}.name`, item["name"]);
    setValue(`items.${index}.itemId`, item._id);

    setValue(`items.${index}.rate`, Number(item["mrp"]));

    setValue(`items.${index}.subTotal`, Number(item.rate.toFixed(2)));
    setValue(`items.${index}.total`, Number((item.rate * 1.18).toFixed(2)));
    setValue(`items.${index}.type`, item["type"]);

    calculateTotal();

    // Update product
  }

  function calculateTotal() {
    const items = getValues("items");
    let subTotal = 0;
    for (const product of items) {
      subTotal += Number(product.subTotal);
    }

    setValue("subTotal", Number(subTotal).toFixed(2));
    setValue("tax", Number((subTotal * 0.18).toFixed(2)));
    setValue("amount", Number((subTotal * 1.18).toFixed(2)));
  }

  // ITEMS SELECTION
  function onQuantityChange(data) {
    const { qty, index } = data;

    setValue(`items.${index}.qty`, qty);
    const rate = getValues(`items.${index}.rate`);
    setValue(`items.${index}.subTotal`, (qty * rate).toFixed(2));
    setValue(`items.${index}.total`, (qty * rate * 1.18).toFixed(2));

    calculateTotal();
  }

  function onDateChange(data) {
    const { date, type, index } = data;

    // if (type === "endDate") {
    //   const startDate = getValues(`items.${index}.billingPeriod.startDate`);
    //   const endDate = date; // getValues(`items.${index}.billingPeriod.endDate`);

    //   if (!isNaN(new Date(startDate)) && !isNaN(new Date(endDate))) {
    //     if (new Date(startDate).getMonth() !== new Date(endDate).getMonth()) {
    //       notifyFailed("Start Date & End Date must be of same months!!");
    //       return;
    //     }
    //   }
    // }
    if (type === "startDate") {
      //

      setValue(
        `items.${index}.billingPeriod.endDate`,
        moment(
          getFirstAndLastDateOfMonth(new Date(date).getMonth()).lastDate
        ).format("YYYY-MM-DD")
      );
    }

    let date2 =
      type === "startDate"
        ? getValues(`items.${index}.billingPeriod.endDate`) || new Date()
        : getValues(`items.${index}.billingPeriod.startDate`) || new Date();

    const firstDate = getValues(`items.${index}.billingPeriod.startDate`);
    const lastDate = getValues(`items.${index}.billingPeriod.endDate`);

    const daysBetweenCurrentDates = daysBetween(firstDate, lastDate) + 1;
    const daysBetweenDates = daysBetween(date, date2) + 1;

    const finalDaysBetween =
      daysBetweenCurrentDates > 30 ? 30 : daysBetweenCurrentDates;

    const finalDaysBetweenDates = daysBetweenDates > 30 ? 30 : daysBetweenDates;
    const rate = getValues(`items.${index}.rate`);
    const currentRate = rate / finalDaysBetween;
    const qty = getValues(`items.${index}.qty`);
    const updatedRate = finalDaysBetweenDates * currentRate;
    const subTotal = (finalDaysBetweenDates * qty * currentRate).toFixed(2);

    type === "startDate"
      ? setValue(`items.${index}.billingPeriod.startDate`, date)
      : setValue(`items.${index}.billingPeriod.endDate`, date);
    // setValue(`items.${index}.`);
    setValue(`items.${index}.subTotal`, subTotal);
    setValue(`items.${index}.rate`, updatedRate);
    setValue(`items.${index}.total`, (subTotal * 1.18).toFixed(2));

    calculateTotal();
  }

  /**
   * Get the qty and rate, then delete the discount and then calculate the total
   * @param {*} data
   */
  function onDiscountChange(data) {
    const { discount, index } = data;

    setValue(`product.${index}.discount`, discount);

    const rate = getValues(`product.${index}.rate`);
    const qty = getValues(`product.${index}.qty`);

    const subTotal = (rate * qty - Number(discount)).toFixed(2);

    setValue(`product.${index}.subTotal`, Number(subTotal).toFixed(2));
    setValue(`product.${index}.total`, (subTotal * 1.18).toFixed(2));

    calculateTotal();
  }

  function onRoundOff(data) {
    const roundOffValue = Number(data);

    if (Math.abs(roundOffValue) >= 1) {
      return;
    }

    const items = getValues("items");
    let subTotal = 0;
    for (const product of items) {
      subTotal += Number(product.subTotal);
    }

    setValue("subTotal", Number(subTotal).toFixed(2));
    setValue("tax", Number((subTotal * 0.18).toFixed(2)));

    const total = Number((subTotal * 1.18).toFixed(2));

    setValue(`amount`, Number(total + roundOffValue).toFixed(2));
  }

  function onRateChange(data) {
    const { rate, index } = data;

    setValue(`items.${index}.rate`, rate);

    const discount = getValues(`items.${index}.discount`);
    const qty = getValues(`items.${index}.qty`);

    const subTotal = Number((rate * qty - Number(discount)).toFixed(2));

    setValue(`items.${index}.subTotal`, subTotal.toFixed(2));
    setValue(`items.${index}.total`, (subTotal * 1.18).toFixed(2));

    calculateTotal();
  }

  const Input = ({ label, name, required, type = "text" }, onChange) => (
    <>
      <label>{label.charAt(0).toUpperCase() + label.slice(1)}</label>
      <div className="mb-3">
        <input
          {...register(name, { required })}
          type={type}
          className="form-control"
          placeholder={label}
        />
        {errors[name] && <ErrorMessage message={errors[name]?.message} />}
      </div>
    </>
  );

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  console.log("[SelectedMOnth", selectedMonth);
  // Watch for Items changes
  return (
    <div style={{ padding: "20px" }} className="performa">
      <h2 className="text-3xl font-bold text-black">Proforma</h2>

      <div className="search-customer-container">
        <input
          type="text"
          value={searchQuery}
          placeholder="Search Customer"
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        />
        {customerData.length > 0 && isDropdownVisible && (
          <div className="search-customer-result">
            {customerData.length > 0 &&
              customerData.map((cus) => {
                return (
                  <div
                    key={`${cus.first_name}${cus.last_name}`}
                    onClick={() => handleCustomerSearch(cus)}
                    className="customer-container"
                    ref={searchCustomerDropdownRef}
                  >
                    <p>
                      {cus.first_name} {cus.last_name}{" "}
                      {cus.clientName ? `(${cus?.clientName})` : ""}
                    </p>
                  </div>
                );
              })}
          </div>
        )}
      </div>

      {/* <select */}
      {customers.length > 1 && (
        <Select
          label="Select Customer"
          value={selectedCustomer}
          // value={billToDropdown.address}
          sx={{ width: "200px" }}
          onChange={handleCustomerSelect}
          className="address-select"
        >
          <MenuItem
            value={JSON.stringify({
              first_name: "Select",
              last_name: "Customer",
            })}
          >
            Select Customer
          </MenuItem>
          {customers.map((customer) => {
            return (
              <MenuItem value={JSON.stringify(customer)} key={customer._id}>
                {customer.first_name} {customer.last_name}
              </MenuItem>
            );
          })}
        </Select>
      )}

      {selectedCustomer && (
        <form
          className="p4 border border-[#C6D0F0] rounded-lg shadow-lg mb-4 p-4"
          style={{
            border: "1px solid #C6D0F0",
            borderRadius: "10px",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="row">
            <div className="col-6">
              <Input
                label="name"
                name="name"
                register={register}
                required={"This field is required"}
              />
            </div>
            <div className="col-6">
              <Input
                label="GeneratedHex"
                name="generatedHex"
                register={register}
              />
            </div>
            <div className="col-6">
              <label>Select Invoice Series</label>
              <div className="mb-3">
                <Controller
                  name="invoiceType"
                  control={control}
                  rules={{
                    required: "Invoice Type is required",
                  }}
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      options={invoiceTypes.map((d) => ({
                        label: d.name,
                        value: d._id,
                      }))}
                      onChange={(selected) => {
                        field.onChange(selected);
                      }}
                      value={invoiceTypes
                        .map((inv) => ({
                          label: inv.name,
                          value: inv._id,
                        }))
                        .find((inv) => {
                          return inv.value === getValues("invoiceType").value;
                        })}
                    />
                  )}
                />

                {errors.invoiceType && (
                  <ErrorMessage message={errors.invoiceType?.message} />
                )}
              </div>
            </div>
          </div>

          {watch("invoiceType").value === "66b0801cc023c7420016310d" && (
            <Paper
              sx={{
                padding: "5px 10px",
                marginBottom: "10px",
              }}
            >
              <h4>Smart Meter Billing</h4>

              <MonthSelector
                selectedMonth={selectedMonth}
                setSelectedMonth={setSelectedMonth}
              />
              {/* Client & Property Selection */}

              {(selectedMonth === 0 || selectedClient) && (
                <div className="row">
                  <div className="col-6">
                    <label>Select Client</label>
                    <div className="mb-3">
                      <Controller
                        name="clientId"
                        control={control}
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            options={clientsData.map((d) => ({
                              value: d._id,
                              label: `${d.name}`,
                            }))}
                            onChange={(selected) => {
                              setSelectedClient(selected.value);
                              setValue("properties", []);
                              field.onChange(selected.value);
                            }}
                            value={clientsData
                              .map((d) => ({
                                value: d._id,
                                label: d.name,
                              }))
                              .find((client) => {
                                return client.value === getValues(`clientId`);
                              })}
                          />
                        )}
                      />
                    </div>
                  </div>

                  {/* Select Property */}
                  {propertyData.length > 0 && (
                    <div className="col-6">
                      <label>Select Property</label>
                      <div className="mb-3">
                        <Controller
                          name="properties"
                          control={control}
                          render={({ field }) => (
                            <ReactSelect
                              {...field}
                              options={propertyData.map((d) => ({
                                value: d._id,
                                label: `${d.name}`,
                              }))}
                              onChange={(selected) => {
                                handleAddProperties([selected]);
                                field.onChange(selected);
                              }}
                              value={propertyData
                                .map((d) => ({
                                  value: d._id,
                                  label: d.name,
                                }))
                                .find(
                                  (d) => d.value === getValues("properties")[0]
                                )}
                              // isMulti
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Paper>
          )}

          {/* Bill To */}
          <div>
            <div className="w-full border-b-2 my-3 p-1">
              <div className="text-xl text-bold">Bill To</div>

              <div className="row mt-2">
                <div className="col-6">
                  <ReactSelect
                    placeholder="Select BillTo Address"
                    options={selectedCustomer?.addresses?.map((addr) => ({
                      label: addr.address,
                      value: addr,
                    }))}
                    onChange={handleBillToSelectAddress}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <label>Name</label>
                <div className="mb-3">
                  <input
                    {...register("billTo.name", {
                      required: "Name is required",
                    })}
                    type="text"
                    className="form-control"
                    placeholder="Name"
                  />
                  {errors.billTo?.name && (
                    <ErrorMessage message={errors.billTo?.name?.message} />
                  )}
                </div>
              </div>

              <div className="col-6">
                <label>Address</label>
                <div className="mb-3">
                  <input
                    {...register("billTo.address", {
                      required: "Address is required",
                    })}
                    type="text"
                    className="form-control"
                    placeholder="Address"
                  />
                  {errors.billTo?.address && (
                    <ErrorMessage message={errors.billTo?.address?.message} />
                  )}
                </div>
              </div>

              <div className="col-6">
                <label>City</label>
                <div className="mb-3">
                  <input
                    {...register("billTo.city", {
                      required: "City is required",
                    })}
                    type="text"
                    className="form-control"
                    placeholder="City"
                  />
                  {errors.billTo?.city && (
                    <ErrorMessage message={errors.billTo?.city?.message} />
                  )}
                </div>
              </div>

              <div className="col-6">
                <label>State</label>
                <div className="mb-3">
                  <Controller
                    name="billTo.state"
                    control={control}
                    defaultValue={null}
                    rules={{ required: "State is required" }}
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        options={INDIANSTATES}
                        isClearable
                        placeholder="Select a state"
                        onChange={(selected) => field.onChange(selected.value)}
                        value={INDIANSTATES.find(
                          (state) =>
                            state.value.toLowerCase() ===
                              getValues("billTo.state")?.toLowerCase() || ""
                        )}
                      />
                    )}
                  />
                  {/* <Controller
                    name="billTo.state"
                    control={control}
                    rules={{
                      required: "State is required",
                    }}
                    render={({ field }) => (
                      <StateSelect
                        name="stateB"
                        countryid={101}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.name);
                          // field.value(
                          //     watch("billTo.state")
                          // );
                        }}
                        placeHolder="Select State"
                      />
                    )}
                  /> */}
                  {errors.billTo?.state && (
                    <ErrorMessage message={errors.billTo?.state?.message} />
                  )}
                </div>
              </div>

              <div className="col-6">
                <label>Country</label>
                <div className="mb-3">
                  <input
                    {...register("billTo.country", {
                      required: "Country is required",
                    })}
                    type="text"
                    className="form-control"
                    placeholder="Country"
                  />
                  {errors.billTo?.country && (
                    <ErrorMessage message={errors.billTo?.country?.message} />
                  )}
                </div>
              </div>

              <div className="col-6">
                <label>PinCode</label>
                <div className="mb-3">
                  <input
                    {...register("billTo.pinCode", {
                      required: "PinCode is required",
                      maxLength: 6,
                    })}
                    type="number"
                    className="form-control"
                    placeholder="PinCode"
                  />
                  {errors.billTo?.pinCode && (
                    <ErrorMessage message={errors.billTo?.pinCode?.message} />
                  )}
                </div>
              </div>

              <div className="col-6">
                <label>GstNumber</label>
                <div className="mb-3">
                  <input
                    {...register("billTo.gstNumber")}
                    type="text"
                    className="form-control"
                    placeholder="GstNumber"
                  />
                  {errors.billTo?.gstNumber && (
                    <ErrorMessage message={errors.billTo?.gstNumber?.message} />
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Ship To */}
          <div>
            <div className="w-full border-b-2 my-3 p-1">
              <div className="text-xl text-bold">Ship To</div>

              {/* Auto-Fill Checkbox */}
              <div>
                <Controller
                  name="autoFill"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            handleShipToAutofill(e.target.checked);
                          }}
                          value={getValues("autoFill")}
                        />
                      }
                      label="Same as Bill To"
                    />
                  )}
                />
              </div>
            </div>

            {!watch("autoFill") && (
              <div>
                {/* <Select
                                    value={JSON.stringify(shipToDropdown)}
                                    sx={{ width: "300px" }}
                                    onChange={handleShipToSelectAddress}
                                    className="address-select"
                                >
                                    <MenuItem
                                        value={JSON.stringify({
                                            address: "Select Address",
                                        })}
                                    >
                                        Select Address
                                    </MenuItem>
                                    {selectedCustomer.addresses &&
                                        selectedCustomer?.addresses.map(
                                            (addr) => (
                                                <MenuItem
                                                    value={JSON.stringify(addr)}
                                                    key={addr.address}
                                                >
                                                    {addr.address}
                                                </MenuItem>
                                            )
                                        )}
                                </Select> */}

                <div className="row">
                  <div className="col-6 mb-2">
                    <ReactSelect
                      placeholder="Select Ship To Address"
                      options={selectedCustomer?.addresses?.map((addr) => ({
                        label: addr.address,
                        value: addr,
                      }))}
                      onChange={handleShipToSelectAddress}
                    />
                  </div>
                </div>
                <div className="row">
                  {["name", "address", "city"].map((field) => {
                    return (
                      <div className="col-6" key={field}>
                        <Input
                          label={field.charAt(0).toUpperCase() + field.slice(1)}
                          name={`shipTo.${field}`}
                          required={`${
                            field.charAt(0).toUpperCase() + field.slice(1)
                          } is required`}
                        />
                        {Object.keys(errors).length > 0 &&
                          errors?.shipTo?.[field] && (
                            <ErrorMessage
                              message={errors?.shipTo[field]?.message}
                            />
                          )}
                      </div>
                    );
                  })}

                  <div className="col-6">
                    <label>State</label>
                    <div className="mb-3">
                      <Controller
                        name="shipTo.state"
                        control={control}
                        defaultValue={null}
                        rules={{ required: "State is required" }}
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            options={INDIANSTATES}
                            isClearable
                            placeholder="Select a state"
                            value={INDIANSTATES.find(
                              (state) =>
                                state.value.toLowerCase() ===
                                  getValues("shipTo.state").toLowerCase() || ""
                            )}
                          />
                        )}
                      />
                      {errors?.shipTo?.state && (
                        <ErrorMessage
                          message={errors?.shipTo?.state?.message}
                        />
                      )}
                    </div>
                  </div>

                  <div className="col-6">
                    <Input
                      label={"Country"}
                      name={`shipTo.country`}
                      required={`Country is required`}
                    />

                    {errors?.shipTo?.country && (
                      <ErrorMessage
                        message={errors?.shipTo?.country?.message}
                      />
                    )}
                  </div>
                  <div className="col-6">
                    <label>PinCode</label>
                    <div className="mb-3">
                      <input
                        {...register(`shipTo.pinCode`, {
                          required: "Pincode is Required!!",
                          minLength: {
                            value: 6,
                            message:
                              "PinCode must be exactly 6 characters long",
                          },
                          maxLength: {
                            value: 6,
                            message:
                              "PinCode must be exactly 6 characters long",
                          },
                        })}
                        type="number"
                        className="form-control"
                        placeholder="PinCode"
                      />
                      {errors?.shipTo?.pinCode && (
                        <ErrorMessage
                          message={errors?.shipTo.pinCode?.message}
                        />
                      )}
                    </div>
                  </div>

                  <div className="col-6">
                    <label>GST Number</label>
                    <div className="mb-3">
                      <input
                        {...register(`shipTo.gstNumber`)}
                        className="form-control"
                        placeholder="GST Number"
                      />
                      {errors?.shipTo?.gstNumber && (
                        <ErrorMessage
                          message={errors?.shipTo?.gstNumber?.message}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* PRODUCT */}

          <Box component="div">
            <Paper elevation={1} sx={{ width: "100%", padding: "20px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "40px",
                }}
              >
                <p style={{ fontSize: "1.5rem" }}>Products</p>
                <Button
                  sx={{ padding: "0px 10px", height: "40px" }}
                  variant="contained"
                  size="small"
                  startIcon={<Cached sx={{ height: "40px" }} />}
                  onClick={refreshItemsList}
                >
                  Items List
                </Button>
              </div>
              {itemFields.map((items, index) => (
                <Paper sx={{ p: 2, marginBottom: "10px" }} key={items.id}>
                  <div className="row mb-4">
                    <div className="col-6">
                      <label>Product List</label>
                      {/* <Select
                                            label="Age"
                                            sx={{
                                                width: "100%",
                                                padding: "5px",
                                            }}
                                            onChange={(e) =>
                                                onSelectItem(e.target.value)
                                            }
                                        >
                                            {displayItems.map((item) => (
                                                <MenuItem
                                                    value={JSON.stringify({
                                                        index,
                                                        item,
                                                    })}
                                                >
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select> */}

                      <ReactSelect
                        options={displayItems.map((item) => ({
                          label: item.name,
                          value: { item, index },
                        }))}
                        onChange={onSelectItem}
                        placeholder="Select Products..."
                      />
                    </div>
                    <div className="col-2">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingTop: "25px",
                        }}
                      >
                        {/* <label>S</label> */}
                        <Button
                          sx={{ height: "40px" }}
                          variant="outlined"
                          onClick={() => {
                            removeItem(index);
                            calculateTotal();
                          }}
                          color="error"
                          startIcon={<DeleteForever />}
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-1"></div>
                    <div className="col-2">
                      <Input
                        label="name"
                        name={`items.${index}.name`}
                        required={`name is required`}
                      />
                    </div>

                    {/* Start Date */}
                    <div className="col-2">
                      <label>Start Date</label>
                      <div className="mb-3">
                        <input
                          type="date"
                          className="form-control"
                          style={{
                            padding: "3px 10px",
                            borderRadius: "5px",
                            border: "1px solid #ced4da",
                          }}
                          {...register(
                            `items.${index}.billingPeriod.startDate`
                          )}
                          onChange={(e) => {
                            onDateChange({
                              date: e.target.value,
                              type: "startDate",
                              index: index,
                            });
                          }}
                        />
                        {errors[`items.${index}.billingPeriod.startDate`] && (
                          <ErrorMessage
                            message={
                              errors[`items.${index}.billingPeriod.startDate`]
                            }
                          />
                        )}
                      </div>
                    </div>

                    {/* End Date */}
                    <div className="col-2">
                      <label>End Date</label>
                      <div className="mb-3">
                        <input
                          type="date"
                          className="form-control"
                          style={{
                            padding: "3px 10px",
                            borderRadius: "5px",
                            border: "1px solid #ced4da",
                            height: "100%",
                          }}
                          min={getValues(
                            `items.${index}.billingPeriod.startDate`
                          )}
                          max={moment(
                            getFirstAndLastDateOfMonth(
                              new Date(
                                getValues(
                                  `items.${index}.billingPeriod.startDate`
                                )
                              ).getMonth()
                            ).lastDate
                          ).format("YYYY-MM-DD")}
                          {...register(`items.${index}.billingPeriod.endDate`)}
                          onChange={(e) => {
                            onDateChange({
                              date: e.target.value,
                              type: "endDate",
                              index: index,
                            });
                          }}
                        />
                        {errors[`items.${index}.billingPeriod.endDate`] && (
                          <ErrorMessage
                            message={
                              errors[`items.${index}.billingPeriod.endDate`]
                            }
                          />
                        )}
                      </div>
                    </div>

                    {/* Devices */}
                    <div className="col-4">
                      <label>Devices</label>
                      <div className="mb-3">
                        <input
                          type="text"
                          {...register(`items.${index}.devices`)}
                          className="form-control"
                          disabled={
                            watch("invoiceType").value ===
                            "66b0801cc023c7420016310d"
                              ? true
                              : false
                          }
                        />
                        {errors[`items.${index}.devices`] && (
                          <ErrorMessage
                            message={errors[`items.${index}.devices`]}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10">
                      <label>Details</label>
                      <div className="mb-3">
                        <input
                          type="text"
                          {...register(`items.${index}.details`, {
                            required: `Details is required`,
                          })}
                          className="form-control"
                          disabled
                        />
                        {errors[`items.${index}.details`] && (
                          <ErrorMessage
                            message={errors[`items.${index}.details`]}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1"></div>
                    <div className="col-2">
                      <label>Rate</label>
                      <div className="mb-3">
                        <input
                          type="number"
                          {...register(`items.${index}.rate`, {
                            required: `Rate is required`,
                          })}
                          step="any"
                          onChange={(e) => {
                            onRateChange({
                              rate: e.target.value,
                              index,
                            });
                          }}
                          className="form-control"
                        />
                        {errors[`items.${index}.rate`] && (
                          <ErrorMessage
                            message={errors[`items.${index}.rate`]}
                          />
                        )}
                      </div>
                    </div>

                    {["qty", "discount", "subTotal", "total"].map(
                      (field, i) => (
                        <div className="col-2" key={i}>
                          <label>
                            {field.charAt(0).toUpperCase() + field.slice(1)}
                          </label>
                          <div className="mb-3">
                            <input
                              type="number"
                              {...register(`items.${index}.${field}`, {
                                required: `${
                                  field.charAt(0).toUpperCase + field.slice(1)
                                } is required`,
                              })}
                              onChange={(e) => {
                                if (field === "qty") {
                                  onQuantityChange({
                                    qty: e.target.value,
                                    index: index,
                                  });
                                } else if (field === "discount") {
                                  onDiscountChange({
                                    discount: e.target.value,
                                    index,
                                  });
                                } else if (field === "rate") {
                                  onRateChange({
                                    rate: e.target.value,
                                    index,
                                  });
                                }
                              }}
                              disabled={
                                field === "subTotal" || field === "total"
                                  ? true
                                  : false
                              }
                              className="form-control"
                            />
                            {errors[`items.${index}.${field}`] && (
                              <ErrorMessage
                                message={errors[`items.${index}.${field}`]}
                              />
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </Paper>
              ))}
              <div
                className="row"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: "25px",
                }}
              >
                {/* <label>S</label> */}
                <Button
                  sx={{ width: "150px" }}
                  variant="contained"
                  onClick={() =>
                    appendItem({
                      itemId: "",
                      name: "",
                      code: "",
                      details: "",
                      hsnCode: "",
                      qty: 1,
                      rate: 0,
                      mrp: 0,
                      cgst: 0,
                      igst: 0,
                      sgst: 0,
                      subTotal: 0,
                      total: 0,
                      discount: 0,
                      devices: "",
                      billingPeriod: {
                        startDate: "",
                        endDate: "",
                      },
                    })
                  }
                  disabled={
                    watch("invoiceType").value === "66b0801cc023c7420016310d" &&
                    propertyData.length === 0
                  }
                >
                  Add Product
                </Button>
              </div>
            </Paper>
          </Box>

          {/* TOTAL */}
          <div className="mt-4">
            <div className="row">
              <div className="col-7"></div>
              <div className="col-1">
                <p>SubTotal</p>
              </div>
              <div className="col-4">
                <input
                  type="number"
                  {...register("subTotal")}
                  className="form-control"
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-7"></div>
              <div className="col-1">
                <p>tax</p>
              </div>
              <div className="col-4">
                <input
                  type="number"
                  {...register("tax")}
                  className="form-control"
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <div className="col-7"></div>
              <div className="col-1">
                <p>Total</p>
              </div>
              <div className="col-4">
                <input
                  type="number"
                  {...register("amount")}
                  className="form-control"
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <div className="col-7"></div>
              <div className="col-1">
                <p>Round off</p>
              </div>
              <div className="col-4">
                <input
                  type="number"
                  {...register("roundOfValue")}
                  className="form-control"
                  onChange={(e) => onRoundOff(e.target.value)}
                  step="any"
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-5"></div>
              <div className="col-3">
                <p style={{ textAlign: "right" }}>Payment Link Amount</p>
              </div>
              <div className="col-4">
                <input
                  type="number"
                  {...register("paymentLinkAmount")}
                  className="form-control"
                />
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-4">
              <Button
                variant="contained"
                type="submit"
                size="large"
                sx={{ width: "100%" }}
              >
                Submit
              </Button>
            </div>
            <div className="col-4"></div>
            <div className="col-2">
              {paymentLink && (
                <Button
                  startIcon={<ContentPaste />}
                  variant="outlined"
                  onClick={() => copyToClipboard(paymentLink)}
                >
                  {paymentLink}
                </Button>
              )}
            </div>
          </div>
        </form>
      )}
      <SmartMeterDetailsModal
        open={open}
        setOpen={setOpen}
        setShowClient={setShowClient}
        setBillingDetails={setSmartMeterBillingDetails}
      />
    </div>
  );
}
