import React, { useState } from 'react';

import ZohoSubscriptionForm from './ZohoSubscriptionForm';
import ViewZohoSubscription from './ViewZohoSubscriptions';

export default function TabZoho() {
  let [tab, setTab] = useState('View_Zoho_Plan');

  return (
    <div style={{ padding: '0 4%', marginTop: '4vh' }} className='performa'>
      <div style={{ marginBottom: '20px' }}>
    

          <button
            className={
              tab === 'View_Zoho_Plan'
                ? 'btn btn-primary mt-2'
                : 'btn btn-light mt-2'
            }
            onClick={() => setTab('View_Zoho_Plan')}
          >
            View Zoho Plan
          </button>
          <button
          className={
            tab === 'Create_Zoho_Plan'
              ? 'btn btn-primary ml-2 mt-2'
              : 'btn btn-light ml-2 mt-2'
          }
          onClick={() => setTab('Create_Zoho_Plan')}
        >
          Create Zoho Plan
        </button>
       
      </div>
      {tab === 'Create_Zoho_Plan' ? (
        <ZohoSubscriptionForm />
      ) : null}
      {tab === 'View_Zoho_Plan' ? (
        <ViewZohoSubscription/>
      ) : null}
     
    </div>
  );
}
