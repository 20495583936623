import React, { useState } from "react";
import { toast } from "react-toastify";
import { createPlan } from "../../apis/subscriptionApis";

const ZohoSubscriptionForm = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    plan_code: "",
    name: "",
    recurring_price: "",
    interval: "",
    interval_unit: "--Select Plan--",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const id = toast.loading("Loading..");
    try {
      if (formData.interval_unit === "--Select Plan--") {
        toast.error("Please Select Interval Unit");
        toast.dismiss(id);
        setLoading(false);
        return;
      }
      const response = await createPlan(formData);
      if (!response) {
        throw Error("Subscripton Failed");
      }
      toast.success("Zoho Subscription Craeted..");
    }
    catch (err) {
      toast.error("Subscription Failed..")
      console.log("Error in handle submit in create plan");
    }
    setLoading(false);
    toast.dismiss(id);
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "100%",
          marginTop: "20px",
        }}
      >
        <h2 style={{ textAlign: "center" }}>Create Subscription Plan</h2>
        <form
          onSubmit={handleSubmit}
          style={{
            width: "80%",
            border: "1px solid black",
            margin: "0 auto",
            borderRadius: "10px",
            padding: "1rem",
            marginTop: "1rem",
            boxSizing: "border-box",
          }}
        >
          <div style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
            <div style={{ flex: 1 }}>
              <label
                htmlFor="plan_code"
                style={{ display: "block", marginBottom: "0.4rem" }}
              >
                Plan Code
              </label>
              <input
                type="text"
                id="plan_code"
                name="plan_code"
                value={formData.plan_code}
                onChange={handleChange}
                className="form-control"
                required
                placeholder="Enter Plan Code.."
                style={{
                  width: "90%",
                  padding: "0.3rem",
                  borderRadius: "5px",
                  border: "0.25px solid black",
                }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <label
                htmlFor="name"
                style={{ display: "block", marginBottom: "0.4rem" }}
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                className="form-control"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                placeholder="Enter Name.."
                style={{
                  width: "90%",
                  padding: "0.3rem",
                  borderRadius: "5px",
                  border: "0.25px solid black",
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
            <div style={{ flex: 1 }}>
              <label
                htmlFor="recurring_price"
                style={{ display: "block", marginBottom: "0.4rem" }}
              >
                Recurring Price
              </label>
              <input
                type="number"
                id="recurring_price"
                className="form-control"
                name="recurring_price"
                value={formData.recurring_price}
                onChange={handleChange}
                required
                placeholder="Enter Price.."
                style={{
                  width: "90%",
                  padding: "0.3rem",
                  borderRadius: "5px",
                  border: "0.25px solid black",
                }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <label
                htmlFor="interval"
                style={{ display: "block", marginBottom: "0.4rem" }}
              >
                Interval
              </label>
              <input
                type="number"
                id="interval"
                name="interval"
                value={formData.interval}
                onChange={handleChange}
                required
                placeholder="Enter Interval.."
                className="form-control"
                style={{
                  width: "90%",
                  padding: "0.3rem",
                  borderRadius: "5px",
                  border: "0.25px solid black",
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
            <div style={{ flex: 1 }}>
              <label
                htmlFor="interval_unit"
                style={{ display: "block", marginBottom: "0.4rem" }}
              >
                Interval Unit
              </label>
              <select
                className="form-control"
                id="interval_unit"
                name="interval_unit"
                value={formData.interval_unit}
                onChange={handleChange}
                required
                style={{
                  width: "90%",
                  padding: "0.3rem",
                  borderRadius: "5px",
                  border: "0.25px solid black",
                  textAlign: "center",
                }}
              >
                <option value="--Select Plan--" selected>
                  --Select Plan--
                </option>
                <option value="months">Months</option>
                <option value="years">Years</option>
                <option value="weeks">Weeks</option>
              </select>
            </div>
            <div style={{ flex: 1 }}>
              <label
                htmlFor="description"
                style={{ display: "block", marginBottom: "0.4rem" }}
              >
                Description
              </label>
              <textarea
                className="form-control"
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
                rows={1}
                placeholder="Enter Description.."
                style={{
                  width: "90%",
                  padding: "0.3rem",
                  borderRadius: "5px",
                  border: "0.25px solid black",
                }}
              />
            </div>
          </div>
          <button
            type="submit"
            disabled={loading}
            style={{
              padding: "0.5rem 0.8rem",
              background: "#007BFF",
              color: "#fff",
              border: "none",
              cursor: `${loading ? "default" : "pointer"}`,
              fontSize: "14px",
              borderRadius: "10px",
            }}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ZohoSubscriptionForm;
