import React, { useEffect, useState } from "react";
import axios from "axios";
import "../ConvertedLead.css";
import "../DispatchData.css";
import moment from "moment/moment";
import "../Installation.css";
import Qutoes from "../Qutoes";
import { pages, serverUrl } from "../ApiUrl";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import "../SpecificElectrician.css";
import { salesUserData } from "../Function/AxiosControl";
import { halfInstallation } from "../Function/AxiosControl";
import { subHistory } from "../Function/SubscriptionHistory";
import CreateHome from "./CreateHome";
import ConfirmatinModel from "./ConfirmatinModel";
import UnAssignElectrician from "./UnAssignElectrician";
import AssignAccordingTimeZone from "./AssignAccordingTimeZone";
import SubscriptionZoneUpdate from "./SubscriptionZoneUpdate";
import PaymentCollect from "../Isp/Electrician/PaymentCollect";
import { Divider, Stack, Button } from "@mui/material";
import UpgradeDownGradeV2 from "./UpgradeDownGrade/UpgradeDownGradeV2";
import Spinner from "../Function/Spinner";
import SiteVisitDoneByElectrician from "./SiteVisitDoneByElectrician";
import { notify, notifyFailed } from "../Common/Notification";
import FittingStarted from "./FittingStarted";
import { orderTyepes, typeOFClient } from "../Data";
import B2BSubscriptionSiteDone from "./B2BSubscriptionSiteDone";
import UpSellDone from "./UpSellDone";
import SiteVisitCreated from "../Address&Layout/SiteVisitCreated";
import ConvertSiteVisitToOther from "../SiteVisit/ConvertSiteVisitToOther";
import FreeClient from "./FreeClinet/FreeClient";
import HouseAccess from "../SmallComponent/HouseAccess";
import {
  distancelog,
  electicianLocationLog,
  getLocationAccess,
  typeOfLocaionLogs,
} from "./api";
import LocationAccess from "./LocationAccess";
import DayStart from "./DayStart";
import OtherLocationLog from "./OtherLocationLog";
// import { Button } from "@material-ui/core";

export default function SpecificElectrician() {
  let teleCallers = useSelector((state) => state.sar?.data?.user?.first_name);
  let employeeId = useSelector((state) => state.sar.data.user.employeeId);
  let loginName = useSelector((state) => state.sar.data.user.first_name);
  let electricianLocation = useSelector((state) => state.electricianLocation);
  let token = useSelector((state) => state.sar?.data?.token);
  let [update, setUpdateData] = useState([]);
  let [halfDones, setHalfDones] = useState(false);
  let [halfDoneSub, setHalfDoneSub] = useState({});
  let [lessThanArray, setLessThanArray] = useState([]);
  let [q, setq] = useState("");
  let [dateIsGivenByCustomer, setDateIsGivenByCustomer] = useState(false);
  let [reson, setReson] = useState(false);
  let [salesUserDatas, setSalesUserDatas] = useState();
  let [websiteReload, SetWebsiteReload] = useState(false);
  let [differntTable, setDiffTable] = useState({});
  let [keys, setKeys] = useState();
  let [loading, setLoading] = useState(false);
  let [locationaccess, setlocationAceess] = useState(false);
  let [locationDistance, setlocationDistance] = useState(0);
  let datas = {};
  const [hasRun, setHasRun] = useState(false);
  const [dayStartShow, setDayStartShow] = useState(false);

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();

      // Check if it's 9:00 AM or later
      if (currentHour > 9 || (currentHour === 9 && currentMinute >= 0)) {
        if (!hasRun) {
          myFunction();
          setHasRun(true);
          console.log("if"); // Ensure the function runs only once per day
        }
      } else {
        // Reset hasRun if it's before 9:00 AM
        setHasRun(false);
        setDayStartShow(false);
        console.log("else");
      }
    };

    // Check every minute
    const interval = setInterval(checkTime, 2000);

    // Initial check
    checkTime();

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [hasRun]);

  const myFunction = () => {
    console.log("It is 9:00 AM or later!");
    distancelog(employeeId)
      .then((res) => {
        if (res.data.length === 0) {
          setDayStartShow(true);
        } else if (res.data.length > 0) {
          let firstLog = res.data.data[0];
          console.log(firstLog, "firstLog");
          if (firstLog.type !== typeOfLocaionLogs.start) {
            setDayStartShow(true);
          } else {
            setDayStartShow(false);
          }
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {}, [electricianLocation]);
  useEffect(() => {
    ReactTooltip.rebuild();
    getLocationAccess()
      .then((res) => {
        console.log(res, "loc");
        if (res.success) {
          setlocationAceess(true);
        } else {
          setlocationAceess(false);
        }
      })
      .catch((err) => {
        setlocationAceess(false);
      });
    todayDistance();
  }, []);
  const todayDistance = async () => {
    let res = await distancelog(employeeId)
      .then((res) => {
        if (res.success) {
          let tt = res.data.reduce((p, c) => {
            return (p += c.distanceFromLastLog);
          }, 0);
          return `${tt.toFixed(3)} Km`;
        } else {
          return "Data Fetch Failed";
        }
      })
      .catch((err) => {
        return "Data Fetch Failed";
      });
    setlocationDistance(res);
  };
  useEffect(() => {
    let data = async () => {
      await axios
        .post(`${serverUrl.u}/api/tele/getInstallationsSites`, {
          electricianId: employeeId,
        })
        .then(async function (response) {
          if (response.data.success) {
            let detail = response.data.data;
            detail.sort(function (a, b) {
              return (
                new Date(a.InstallationDate) - new Date(b.InstallationDate)
              );
            });

            let lessThan = [];
            for (let i of detail) {
              console.log(i.InstallationDate);
              if (
                new Date(i.InstallationDate) < new Date().setHours(0, 0, 0, 0)
              ) {
                lessThan.push(i);
              }
            }
            console.log(lessThan, "less");
            setLessThanArray(lessThan);
            await detail.map((d) => {
              let ddate = moment
                .utc(d.InstallationDate)
                .format("dddd -  Do MMMM");
              if (datas[ddate] === undefined) {
                datas[ddate] = [];
              }
              datas[ddate].push(d);
            });
            let salesUser = await salesUserData(token);
            if (salesUser == undefined) {
              notifyFailed("Please LogOut and Login again");
            }
            setSalesUserDatas(salesUser);
            setDiffTable(datas);
            setLoading(false);
          } else {
            notify(response.data.message);
            setLoading(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
        });
    };
    data();
  }, [websiteReload]);

  useEffect(() => {
    let keya = Object.keys(differntTable);
    setKeys(keya);
  }, [differntTable]);

  const updateReson = async (data) => {
    setReson(!reson);
    let res = await electicianLocationLog(
      employeeId,
      typeOfLocaionLogs.leave,
      update
    );
    if (!res.success) {
      notifyFailed("First Click on Day Start Button");
      return;
    }
    await axios
      .post(`${serverUrl.u}/api/tele/changeInstallationDateByElectrication`, {
        subId: update._id,
        comments: `${loginName}-${data.resonComment} - ${moment(
          new Date()
        ).format("DD-MM-YYYY")}`,
        date: data.dateInstallation,
        dateGivenByCustomer: dateIsGivenByCustomer,
      })
      .then(function (res) {
        if (res.data.success === true) {
          notify(res.data.message);
          setReson(false);
          if (update.siteVisiteByElectrician) {
            subHistory(
              "Site Viste Not Completed",
              update,
              "Site Vist Not Done",
              `${
                dateIsGivenByCustomer === true
                  ? data.dateInstallation
                  : "date not given by customer"
              }`,
              `${loginName}-${data.resonComment} - ${moment(new Date()).format(
                "DD-MM-YYYY"
              )}`
            );
          } else if (update.reeInstallation) {
            subHistory(
              `${loginName}-${data.resonComment} - ${moment(new Date()).format(
                "DD-MM-YYYY"
              )}`,
              update,
              "ReInstallation Not Complete",
              `${
                dateIsGivenByCustomer === true
                  ? data.dateInstallation
                  : "date not given by customer"
              }`,
              `${loginName}-${data.resonComment} - ${moment(new Date()).format(
                "DD-MM-YYYY"
              )}`
            );
          } else {
            subHistory(
              update.toBePickedUp === true
                ? "UnInstallation not Complete"
                : update.grivience === true
                ? "Grivience"
                : "Installation not Complete",
              update,
              "Uninstallation/Installation/Grivience Not Completed",
              `${
                dateIsGivenByCustomer === true
                  ? data.dateInstallation
                  : "date not given by customer"
              }`,
              `${loginName}-${data.resonComment} - ${moment(new Date()).format(
                "DD-MM-YYYY"
              )}`
            );
          }
          SetWebsiteReload(!websiteReload);
        } else {
          notify(res.data.message);
          setReson(true);
        }
      })
      .catch(function (error) {
        console.log(error);
        notify("Error is occured while saving");
      });
  };

  const salesUserNumber = (name, salesUserDatas) => {
    const n = salesUserDatas.find((e) => e.first_name == name);
    return n?.phoneNumbers[0];
  };

  const handleSubmitHalfDone = async (event) => {
    event.preventDefault();
    let res = await electicianLocationLog(
      employeeId,
      typeOfLocaionLogs.leave,
      halfDoneSub
    );
    if (!res.success) {
      notifyFailed("First Click on Day Start Button");
      return;
    }
    const data = new FormData(event.currentTarget);
    let Data = {
      Number: data.get("numberAppliances"),
    };
    halfDone(Number(Data.Number));
  };
  const halfDone = async (app) => {
    let result = await halfInstallation(halfDoneSub.generatedHex, app);
    if (result[1] === "then") {
      console.log(result[0]);
      if (result[0].data.success === true) {
        notify("Successfull Done");
        setHalfDones(false);
        subHistory(
          `Half Installation Done by ${loginName}`,
          halfDoneSub,
          "Half Installation Done",
          "half installation"
        );
      } else {
        notify(result[0].data.message);
      }
    } else {
      console.log(result[0]);
      notify("Error Occur");
    }
  };
  const handleSubmitResonNotComplete = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let Data = {
      resonComment: data.get("resonComment"),
      dateInstallation: data.get("dateInstallation"),
    };
    console.log(Data, "date");
    updateReson(Data);
  };
  const search = (data) => {
    console.log(data, "jjjj");
    if (data === undefined || data.length === 0) return;
    let newData = data.filter((e) =>
      e.name.toUpperCase().includes(q.toUpperCase())
    );
    return newData;
  };

  const handleShowFitting = (sub) => {
    if (sub.fittingStats.length === 0) {
      return true;
    } else if (sub.fittingStats.length !== 0) {
      let lastObj = sub.fittingStats[sub.fittingStats.length - 1];
      if (lastObj.status === "done") {
        return true;
      } else {
        if (
          moment(new Date()).format("DD-MM-YYYY") !==
          moment(lastObj.startTime).format("DD-MM-YYYY")
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  };

  return (
    <div style={{ padding: "0 1%", marginTop: "4vh" }}>
      {/* <div
        style={{
          marginLeft: "2%",
          fontSize: "large",
          fontWeight: "bold",
        }}
      >
        Today Total Distance Travel :- {locationDistance}
      
        <Button variant="contained" sx={{ml:3}} onClick={()=>todayDistance()} color="primary">Refresh</Button>
      </div> */}
      {loading && <Spinner />}
      {/* {!locationaccess && <LocationAccess />} */}
      {/* {!(electricianLocation[new Date().setHours(0,0,0,0)]!==undefined && electricianLocation[new Date().setHours(0,0,0,0)]===true) && hasRun &&<DayStart employeeId={employeeId}/>}
      <div className="input-field">
        <input
          type="text"
          value={q}
          placeholder="Search here...."
          onChange={(e) => setq(e.target.value)}
        />
      </div>
      <div className="d-flex justify-content-between mt-4 gap-3" style={{gap:'15px'}}>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={electricianLocation[new Date().setHours(0,0,0,0)]!==undefined && electricianLocation[new Date().setHours(0,0,0,0)]===true}
                      onClick={() => {
                        electicianLocationLog(
                          employeeId,
                          typeOfLocaionLogs.start,
                          {generatedHex:'start',name:'start',grivience:false,finalInstalled:false}

                        );
                      }}
                    >
                      Day Start
                    </Button>
                    <OtherLocationLog employeeId={employeeId}/>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        electicianLocationLog(
                          employeeId,
                          typeOfLocaionLogs.end,
                          {generatedHex:'end',name:'end',grivience:false,finalInstalled:false}
                        ).then((res) => {
                          if (!res.success) {
                            notifyFailed("First Click on Day Start");
                            return;
                          }
                        });
                      }}
                    >
                      Day End
                    </Button>
                  </div> */}
      {keys && salesUserDatas !== undefined
        ? keys.map((d, index) => {
            differntTable[d]?.sort(
              (a, b) => b.priorityNumber - a.priorityNumber
            );
            return (
              <div
                className=""
                style={{ height: "fit-content", marginLeft: "2%" }}
                key={index}
              >
                <div
                  className="table-date-container"
                  style={{
                    flexDirection: "column",
                    marginLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {" "}
                  <div className="table-date" style={{ marginBottom: "5px" }}>
                    {d}
                  </div>
                </div>
                <div
                  className="table-container"
                  style={{
                    height: "fit-content",
                    marginTop: 0,
                    marginLeft: 0,
                    width: "100%",
                  }}
                >
                  {search(differntTable[d])
                    ?.sort((a, b) => b.priorityNumber - a.priorityNumber)
                    ?.map((e, i) => {
                      return (
                        <div
                          key={i}
                          id={e._id}
                          style={{
                            backgroundColor:
                              e.grivience === true
                                ? "rgb(235 85 82 / 30%)"
                                : "",
                          }}
                        >
                          <table
                            className=" table-bordered "
                            style={{ width: "100%" }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  className="specific-title"
                                  style={{ fontSize: "20px" }}
                                >
                                  Status :
                                </td>
                                <th
                                  className="specific-data"
                                  style={{ fontSize: "20px" }}
                                >
                                  {e.toBePickedUp === true
                                    ? "UnInstallation Site"
                                    : e.grivience === true
                                    ? "Grivience Site"
                                    : e.grivience === false &&
                                      e.finalInstalled === false
                                    ? "Fresh Site"
                                    : ""}{" "}
                                  {e.ispSubscription === true
                                    ? "ISP Client"
                                    : ""}
                                </th>
                              </tr>
                              <tr
                                onClick={() => {
                                  navigator.clipboard.writeText(e.generatedHex);
                                }}
                              >
                                <td className="specific-title">{`Client Name :`}</td>
                                <th className="specific-data">{e.name}</th>
                              </tr>
                              <tr>
                                <td className="specific-title">Phone no :</td>
                                <th className="specific-data">
                                  <a href={`tel:${e.phone}`}>{e.phone}</a>
                                </th>
                              </tr>
                              <tr>
                                <td className="specific-title">
                                  Alternate Phone no :
                                </td>
                                <th className="specific-data">
                                  <a href={`tel:${e.alternatePhone}`}>
                                    {e.alternatePhone}
                                  </a>
                                </th>
                              </tr>
                              <tr>
                                <td className="specific-title">Time :</td>
                                <th className="specific-data">
                                  {moment
                                    .utc(e.InstallationDate)
                                    .format("LT") !== "12:00 AM"
                                    ? moment.utc(e.InstallationDate).calendar()
                                    : "Time Not Given"}
                                </th>
                              </tr>
                              <tr>
                                <td className="specific-title">Order Type :</td>
                                <th className="specific-data">{e.orderType}</th>
                              </tr>
                              <tr>
                                <td className="specific-title">{`Address :`}</td>
                                <th className="specific-data">
                                  <a href={e.addressHyperlink}>{e.address}</a>
                                </th>
                              </tr>
                              <tr>
                                <td className="specific-title">
                                  Sales Agent :
                                </td>
                                <th className="specific-data">
                                  <a
                                    href={`tel:${salesUserNumber(
                                      e.teleCallerId,
                                      salesUserDatas
                                    )}`}
                                  >
                                    {e.teleCallerId}
                                  </a>
                                </th>
                              </tr>
                              <tr>
                                <td className="specific-title">
                                  Second Sales Agent :
                                </td>
                                <th className="specific-data">
                                  <a
                                    href={`tel:${salesUserNumber(
                                      e.secondarySalesPerson,
                                      salesUserDatas
                                    )}`}
                                  >
                                    {e.secondarySalesPerson}
                                  </a>
                                </th>
                              </tr>
                              <tr>
                                <td className="specific-title">{`Payment Status :`}</td>
                                <th
                                  className="specific-data"
                                  style={{
                                    color: `${
                                      e.partialPayment === false ||
                                      e.reatachMandate === true
                                        ? "green"
                                        : e.partialAmount === 0
                                        ? "red"
                                        : "orange"
                                    }`,
                                  }}
                                >
                                  {e.partialPayment === false ||
                                  e.reatachMandate === true
                                    ? "Paid"
                                    : e.partialAmount === 0
                                    ? "Zero"
                                    : "Partial"}
                                </th>
                              </tr>
                              <tr>
                                <td className="specific-title">Appliances :</td>
                                <th className="specific-data">
                                  {e.orderType !== orderTyepes[2].value ? (
                                    e.totalAppliances
                                  ) : (
                                    <ul>
                                      {e.upsellItems.map((product, i) => {
                                        return (
                                          <li>
                                            Product Name - {product.productName}
                                            ,
                                            {/* Selling Per Pic - {product.sellingPricePerPic}, */}
                                            Qty - {product.qty}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  )}
                                </th>
                              </tr>
                              <tr>
                                <td className="specific-title">
                                  New Appliances :
                                </td>
                                <th className="specific-data">
                                  {e.currentSecurity / 100}
                                </th>
                              </tr>
                              <tr>
                                <td className="specific-title">
                                  Plan Frequency :
                                </td>
                                <th className="specific-data">
                                  {e.planFrequency}
                                </th>
                              </tr>
                              <tr>
                                <td className="specific-title">
                                  Admin Comments :
                                </td>
                                <th className="specific-data">
                                  <div className="hover-wide">
                                    {Array.isArray(e.headComments) ? (
                                      <div
                                        style={{ padding: "5px" }}
                                        className="hover-wide"
                                      >
                                        {e.headComments.map((c) => {
                                          if (typeof c === "object") {
                                            return (
                                              <div style={{ padding: 0 }}>
                                                {c.commentText} -{" "}
                                                {moment
                                                  .utc(c.createdAt)
                                                  .format("DD-MMM-YYYY")}{" "}
                                                - {c.createdBy}
                                              </div>
                                            );
                                          } else {
                                            return <div>{c}</div>;
                                          }
                                        })}
                                      </div>
                                    ) : (
                                      e.headComments
                                    )}
                                  </div>
                                </th>
                              </tr>
                              <tr>
                                <td className="specific-title">Comments :</td>
                                <th className="specific-data">{e.comments}</th>
                              </tr>
                              <tr>
                                <td className="specific-title">
                                  Gerivance TeleCaller Comments :
                                </td>
                                <th className="specific-data">
                                  {e.extra2 === "false" ? "" : e.extra2}
                                </th>
                              </tr>
                              <tr>
                                <td className="specific-title">
                                  Gerivance Department Comments :
                                </td>
                                <th className="specific-data">
                                  {e.commentByGrivienceDepartment}
                                </th>
                              </tr>
                              <tr>
                                <td className="specific-title">
                                  Electrician Comments :
                                </td>
                                <th className="specific-data">
                                  {e.installerComments}
                                </th>
                              </tr>
                              <tr>
                                <td>Assigned Electricians : </td>
                                <td>
                                  {e.AssignedElectritian.map(
                                    (e) => e.electricianName
                                  ).join(", ")}
                                </td>
                              </tr>
                              {/* <tr>
                                <td className='specific-title'>
                                  Subscription Zone Incentive :
                                </td>
                                <th className='specific-data'>{e.zone}</th>
                              </tr> */}
                            </tbody>
                          </table>
                          <div
                            style={{
                              display: "flex",
                              gap: 10,
                              flexDirection: "column",
                            }}
                          >
                            {/* <UnAssignElectrician data={e} notify={notify} reload={websiteReload} setReload={SetWebsiteReload} /> */}

                            {e.zone === "" ||
                            e.zone === undefined ||
                            e.zone === "Select Zone" ? (
                              <SubscriptionZoneUpdate
                                subscription={e}
                                notify={notify}
                                setReload={SetWebsiteReload}
                                reload={websiteReload}
                              />
                            ) : null}
                            {e.siteVisiteByElectrician === true ||
                            [
                              typeOFClient[2].value,
                              typeOFClient[4].value,
                            ].includes(e.orderType) ? (
                              <>
                                <Button
                                  variant="contained"
                                  color="error"
                                  sx={{ width: 1 }}
                                  type="small"
                                  onClick={() => {
                                    setReson(!reson);
                                    setUpdateData(e);
                                  }}
                                >
                                  Site Visit Not Complete
                                </Button>
                                <SiteVisitDoneByElectrician
                                  sub={e}
                                  page={pages.elctrician}
                                  reload={websiteReload}
                                  setReload={SetWebsiteReload}
                                  input={true}
                                />
                                {![
                                  typeOFClient[2].value,
                                  typeOFClient[4].value,
                                ].includes(e.orderType) && (
                                  <SiteVisitCreated
                                    sub={e}
                                    page={pages.elctrician}
                                    btnName={"Site Visit to Installation"}
                                    text={
                                      "Are you sure you want to convert this subcription to Installation Site ?"
                                    }
                                  />
                                )}
                                {[
                                  typeOFClient[2].value,
                                  typeOFClient[4].value,
                                ].includes(e.orderType) && (
                                  <ConvertSiteVisitToOther
                                    sub={e}
                                    reload={websiteReload}
                                    setReload={SetWebsiteReload}
                                    page={pages.elctrician}
                                  />
                                )}
                                <a
                                  href={`http://wa.me/${e.phone}?text=Hello sir I am ${teleCallers}  from Aliste Technologies.We have booked Site Visit with you today.Kindly Please Share your location`}
                                  target="_blank"
                                >
                                  <Button
                                    variant="contained"
                                    color="success"
                                    sx={{ width: 1 }}
                                  >
                                    WhatsApp Site Visit
                                  </Button>
                                </a>
                              </>
                            ) : (
                              <>
                                {e.orderType !== orderTyepes[2].value && (
                                  <AssignAccordingTimeZone
                                    data={e}
                                    notify={notify}
                                    name={teleCallers}
                                    reload={websiteReload}
                                    setReload={SetWebsiteReload}
                                  />
                                )}
                                {e?.toBePickedUp === true ? (
                                  <>
                                    <button
                                      className="btn1 btn-danger"
                                      onClick={() => {
                                        setReson(!reson);
                                        setUpdateData(e);
                                      }}
                                    >
                                      UnInstallation Not Complete
                                    </button>
                                    <a
                                      href={`http://wa.me/${e.phone}?text=Hello sir I am ${teleCallers}  from Aliste Technologies.We have booked UnInstallation with you today.Kindly Please Share your location`}
                                      target="_blank"
                                    >
                                      <button className="btn1 btn-success">
                                        WhatsApp
                                      </button>
                                    </a>
                                    {handleShowFitting(e) ? (
                                      <FittingStarted sub={e} />
                                    ) : (
                                      <ConfirmatinModel
                                        data={e}
                                        notify={notify}
                                        setReload={SetWebsiteReload}
                                        reload={websiteReload}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <Stack direction="row">
                                      <item style={{ width: "100%" }}>
                                        <Button
                                          variant="contained"
                                          color="error"
                                          sx={{ width: 1 }}
                                          type="small"
                                          onClick={() => {
                                            setReson(!reson);
                                            setUpdateData(e);
                                          }}
                                        >
                                          {e.orderType === orderTyepes[2].value
                                            ? "UpSell Not Complete"
                                            : e.grivience === true
                                            ? "Grivence Not Complete"
                                            : e.reeInstallation === true
                                            ? "ReInstallation Not Complete"
                                            : "Installation Not Complete"}
                                        </Button>
                                      </item>
                                    </Stack>

                                    {e.houseId === "" &&
                                    e.oldDrop === false &&
                                    e.Is_SubUpgradeDowngrade === false &&
                                    e.orderType !== orderTyepes[2].value ? (
                                      <CreateHome
                                        dataObject={e}
                                        notify={notify}
                                      />
                                    ) : null}
                                    {e.orderType !== orderTyepes[2].value && (
                                      <Button
                                        variant="contained"
                                        color="warning"
                                        onClick={() => {
                                          setHalfDones(true);
                                          setHalfDoneSub(e);
                                        }}
                                      >
                                        {e.reeInstallation
                                          ? "Half ReInstallation"
                                          : "Half Installation"}
                                      </Button>
                                    )}

                                    {e.ispSubscription === false &&
                                    e.IsB2BSubscription === false ? (
                                      e.orderType !== orderTyepes[2].value ? (
                                        <>
                                          {handleShowFitting(e) ? (
                                            <FittingStarted sub={e} />
                                          ) : (
                                            <UpgradeDownGradeV2
                                              sub={e}
                                              setReload={SetWebsiteReload}
                                              reload={websiteReload}
                                            />
                                          )}
                                        </>
                                      ) : handleShowFitting(e) ? (
                                        <FittingStarted sub={e} />
                                      ) : (
                                        <UpSellDone
                                          sub={e}
                                          setReload={SetWebsiteReload}
                                          reload={websiteReload}
                                        />
                                      )
                                    ) : e.ispSubscription === true ? (
                                      <>
                                        {handleShowFitting(e) ? (
                                          <FittingStarted sub={e} />
                                        ) : (
                                          <PaymentCollect
                                            sub={e}
                                            setReload={SetWebsiteReload}
                                            reload={websiteReload}
                                          />
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {handleShowFitting(e) ? (
                                          <FittingStarted sub={e} />
                                        ) : (
                                          <B2BSubscriptionSiteDone
                                            sub={e}
                                            setReload={SetWebsiteReload}
                                            reload={websiteReload}
                                          />
                                        )}
                                      </>
                                    )}
                                    {/* {
                                        handleShowFitting(e) === false && (orderTyepes[0].value === e.orderType || orderTyepes[4].value === e.orderType) && e.partialPayment === true && <FreeClient sub={e} />
                                      } */}

                                    {e?.toBePickedUp === true ? (
                                      <a
                                        href={`http://wa.me/${e.phone}?text=Hello sir I am ${teleCallers}  from Aliste Technologies.We have booked uninstallation with you today.Kindly Please Share your location`}
                                        target="_blank"
                                      >
                                        <Button
                                          variant="contained"
                                          color="success"
                                          sx={{ width: 1 }}
                                        >
                                          WhatsApp UnInstallation
                                        </Button>
                                      </a>
                                    ) : e.siteVisiteByElectrician === true ? (
                                      <a
                                        href={`http://wa.me/${e.phone}?text=Hello sir I am ${teleCallers}  from Aliste Technologies.We have booked Site Visit with you today.Kindly Please Share your location`}
                                        target="_blank"
                                      >
                                        <Button
                                          variant="contained"
                                          color="success"
                                          sx={{ width: 1 }}
                                        >
                                          WhatsApp Site Visit
                                        </Button>
                                      </a>
                                    ) : (
                                      <a
                                        href={`http://wa.me/${
                                          e.phone
                                        }?text=Hello sir I am ${teleCallers}  from Aliste Technologies.We have booked ${
                                          e.grivience === true
                                            ? "Grievance"
                                            : "Installation"
                                        } with you today.Kindly Please Share your location`}
                                        target="_blank"
                                      >
                                        <Button
                                          variant="contained"
                                          color="success"
                                          sx={{ width: 1 }}
                                        >
                                          WhatsApp
                                        </Button>
                                      </a>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            {(e.houseId !== "" ||
                              e.orderType === orderTyepes[7].value) && (
                              <HouseAccess e={e} w={1} />
                            )}
                          </div>
                          <div style={{ marginBottom: "30px" }}></div>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })
        : null}

      {halfDones === true ? (
        <div className="reson-form">
          <span className="reson-cross" onClick={() => setHalfDones(false)}>
            x
          </span>
          <form onSubmit={handleSubmitHalfDone}>
            <label className="specific-label">
              How Many Appliance Installed Today?
            </label>
            <div className="input-group ">
              <input
                type="number"
                className="form-control"
                required
                aria-label="referenceId"
                name="numberAppliances"
                autoComplete="current-numberAppliances"
                aria-describedby="basic-addon1"
              />
            </div>

            <button className="btn2 btn-primary mt-2 ">Done</button>
          </form>
        </div>
      ) : null}

      {reson === true ? (
        <div className="reson-form">
          <span className="reson-cross" onClick={() => setReson(!reson)}>
            x
          </span>
          <form onSubmit={handleSubmitResonNotComplete}>
            <label className="specific-label">Reson</label>
            <div className="input-group ">
              <input
                type="text"
                className="form-control"
                required
                aria-label="referenceId"
                name="resonComment"
                autoComplete="current-resonComment"
                aria-describedby="basic-addon1"
              />
            </div>
            <label className="specific-label">
              Date and Time Given by Customer
            </label>
            <div className="input-group mb-1">
              <select
                class="custom-select"
                id="inputGroupSelect01"
                defaultValue="false"
                onChange={(event) =>
                  setDateIsGivenByCustomer(
                    event.target.value === "true" ? true : false
                  )
                }
              >
                <option value={"false"}>No</option>
                <option value={"true"}>Yes</option>
              </select>
            </div>
            {dateIsGivenByCustomer === true ? (
              <>
                <label className="create-label1">
                  Installation Date and Time
                </label>
                <div className="input-group mb-1">
                  <input
                    className="form-control"
                    required
                    type="datetime-local"
                    name="dateInstallation"
                    autoComplete="current-dateInstallation"
                  />
                </div>
              </>
            ) : null}

            <button className="btn2 btn-primary mt-2 ">Done</button>
          </form>
        </div>
      ) : null}
      {lessThanArray.length !== 0 ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: "0",
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            overflowY: "scroll",
            zIndex: 4,
          }}
        >
          <div
            style={{
              width: "90%",
              backgroundColor: "whitesmoke",
              padding: "10px",
              borderRadius: "12px",
              position: "relative",
              top: "120px",
              height: "fit-content",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <span
                onClick={() => setLessThanArray([])}
                style={{ cursor: "pointer" }}
              >
                <a href={`#${lessThanArray[0]._id}`}>X</a>
              </span>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" style={{ width: "30%" }}>
                    Name
                  </th>
                  <th scope="col" style={{ width: "30%" }}>
                    Installation Date
                  </th>
                  <th scope="col" style={{ width: "30%" }}>
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {lessThanArray.map((e) => {
                  return (
                    <tr>
                      <td>{e.name}</td>
                      <td>
                        {moment(e.InstallationDate).format("DD-MMM-YYYY")}
                      </td>
                      <td>Pending</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
      <Qutoes />
    </div>
  );
}
