import React, { useEffect, useState } from "react";
import { apiRequest } from "../../utils";
import { serverUrl } from "../../component/ApiUrl";
import { notify, notifyFailed } from "../../component/Common/Notification";
import {
  Done,
  Download,
  RepeatOneSharp,
  RestaurantSharp,
} from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Spinner from "../../component/Function/Spinner";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";

export default function PiWIthoutInvoice() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  let userRoles = useSelector((state) => state.sar.data?.user?.roles);

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const response = await apiRequest({
        url: `${serverUrl.test}/v3/performa/PIWithoutInvoice`,
        method: "GET",
      });

      if (!response.data.success) {
        notifyFailed(response.data.message);
        return;
      }

      setData(response.data.data.PIs);
      setLoading(false);
    }

    getData();
  }, []);

  const registerInvoice = async (data) => {
    const payload = {
      ...data.rawData,
      billingDate: moment().format("YYYY-M-D"),
      invoiceDate: moment().format("YYYY-M-D"),
      billingDetails: data.rawData.billTo,
      shippingDetails: data.rawData.shipTo,
      isFirstInvoice: true,
      items: data.rawData.items,
      piNumber: data.rawData._id,
      paymentStatus: data.payments?.length > 0 ? "received" : "created",

      payments: [
        {
          invoices: [],
          amount: data.amount,
          method: "razorpay",
          details: {},
          verified: true,
          mode: "upi",
          referenceId: data.payments[0]?.id,
          comments: [],
          verificationDetail: {},
          status: data.payments?.length > 0 ? "received" : "created",
          customerId: data.customerId,
        },
      ],
    };

    const response = await apiRequest({
      url: `${serverUrl.test}/v3/invoices/register`,
      data: payload,
      method: "POST",
    });

    if (!response.data.success) {
      notifyFailed(response.data.message);
      return;
    }

    notify(response.data.message || "Invoice Registered.");
  };
  const columns = [
    {
      field: "name",
      headerName: "Customer Name",
      width: 250,
    },
    {
      field: "piNumber",
      headerName: "Proforma Number",
      width: 150,
    },
    {
      field: "item",
      headerName: "Number of Products",
    },
    {
      field: "items",
      headerName: "Product Names",
      width: 250,
    },
    {
      field: "amount",
      headerName: "Total Amount",
      width: 250,
    },
    {
      field: "billTo",
      headerName: "Billing Address",
      width: 250,
    },
    {
      field: "shipTo",
      headerName: "Shipping Address",
      width: 250,
    },
  ];

  // Add action buttons
  if (userRoles.includes("Super Admin")) {
    columns.push({
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => registerInvoice(params.row)}
          >
            <Done />
          </Button>
        </div>
      ),
    });
  }
  const rows = data.map((pi, i) => {
    return {
      ...pi,

      id: i, // Using index as unique ID
      items:
        pi.items.length > 0
          ? pi.items.map((p) => p.name).join(", ")
          : pi.product.map((p) => p.name).join(", "),
      billTo: pi.billTo
        ? `${pi.billTo.address} ${pi.billTo.city}, ${pi.billTo.country}`
        : undefined,
      shipTo: pi.shipTo
        ? `${pi.shipTo.address} ${pi.shipTo.city}, ${pi.shipTo.country}`
        : undefined,
      item: pi.items.length > 0 ? pi.items.length : pi.product.length,
      rawData: pi,
    };
  });

  return (
    <div>
      <h2>Proforma Invoices without Invoice</h2>

      {loading ? (
        <Spinner />
      ) : (
        <div className="mt-4">
          {/* Table */}
          <div
            className="table-container"
            style={{
              border: "1px solid #c6d0f0",
              borderRadius: "10px",
            }}
          >
            <DataGrid
              sx={{ minHeight: "70vh" }}
              columns={columns}
              rows={rows}
              components={{
                Toolbar: GridToolbar,
              }}
              componentsProps={{
                toolbar: {
                  csvOptions: {
                    fileName: `pi_without_invoice_${new Date().toLocaleDateString()}`,
                  },
                },
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10, 20, 30, 40, 50]}
            />
          </div>
        </div>
      )}
    </div>
  );
}
