import { Button } from "@mui/material";
import axios from "axios";
import React from "react";
import { serverUrl } from "../ApiUrl";
import { notify, notifyFailed } from "../Common/Notification";

const creditNotes = async (phone) => {
  return await axios
    .post(`${serverUrl.u}/api/tele/getAllAvailableCreditNoteList`, {
      phone,
    })
    .then(function (res) {
      return { res: res };
    })
    .catch(function (err) {
      return { err: err };
    });
};

function CreditNote({ sub, setCreditNotes }) {
  return (
    <>
      <button
        className="btn btn-primary"
        size="small"
        onClick={async () => {
          let result = await creditNotes(sub.phone);
          console.log(result, "credit note");
          if (result.res !== undefined) {
            if (result.res.data.success) {
              notify(result.res.data.message);
              if (result.res.data.data.success) {
                setCreditNotes(result.res.data.data.creditNotes);
              } else {
                setCreditNotes([]);
              }
            } else {
              notifyFailed(result.res.data.message);
            }
          } else if (result.err !== undefined) {
            notifyFailed(result.err.response.data.message);
          }
        }}
      >
        Get Credit Notes
      </button>
    </>
  );
}

export default CreditNote;
