import {
  Button,
  Modal,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import { planDuration } from "../Data";
import axios from "axios";
import { serverUrl } from "../ApiUrl";
import { notify, notifyFailed } from "../Common/Notification";

const MakeCreditNote = ({ inv, sub }) => {
  const [open, setOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState({
    name: inv.name.trim(),
    phone: sub.phone,
    generatedHex: inv.generatedHex,
    zohoInvoiceNumber: inv.zohoInvoiceNumber,
    invoiceNumber: inv.invoiceNumber,
    amount: 0,
    invoiceAmount: inv.actualTotalAmount,
    status: "overdue",
    duration: {
      firstDate: "",
      lastDate: "",
      planFrequency: "",
      totalAppliances: 0,
      unusedDays: 0,
      comments: "",
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name in invoiceData.duration) {
      setInvoiceData({
        ...invoiceData,
        duration: {
          ...invoiceData.duration,
          [name]: value,
        },
      });
    } else {
      setInvoiceData({
        ...invoiceData,
        [name]: value,
      });
    }
  };

  const handleSubmit = () => {
    let data = {
      ...invoiceData,
      amount: Number(invoiceData.amount),
      duration: {
        ...invoiceData.duration,
        totalAppliances: Number(invoiceData.duration.totalAppliances),
        unusedDays: Number(invoiceData.duration.unusedDays),
      },
      invoiceAmount: Number(invoiceData.invoiceAmount),
    };

    axios
      .post(`${serverUrl.test}/api/tele/registerCreditNotes`, data)
      .then((res) => {
        if (res.data.success) {
          notify(res.data.message);
          setOpen(false);
        } else {
          notifyFailed(res.data.message);
        }
      })
      .catch((err) => {
        let msg = err?.response?.data?.message
          ? err?.response?.data?.message
          : err?.message;
        notifyFailed(msg);
      });
  };

  return (
    <div>
      <button
        className="btn btn-primary"
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        Credit Note
      </button>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            maxWidth: "500px",
            margin: "0 auto",
            padding: "20px",
            fontFamily: "Arial, sans-serif",
            backgroundColor: "white",
            borderRadius: 2,
            boxShadow: 24,
            overflowY: "scroll",
            height: "100%",
          }}
        >
          <h2>Make Credit Note</h2>

          <Grid container spacing={2}>
            {/* Name Field */}
            <Grid item xs={12}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                name="name"
                value={invoiceData.name}
                onChange={handleChange}
              />
            </Grid>

            {/* Phone Field */}
            <Grid item xs={12}>
              <TextField
                label="Phone"
                variant="outlined"
                fullWidth
                name="phone"
                value={invoiceData.phone}
                onChange={handleChange}
              />
            </Grid>

            {/* Generated Hex Field */}
            <Grid item xs={12}>
              <TextField
                label="Generated Hex"
                variant="outlined"
                fullWidth
                name="generatedHex"
                value={invoiceData.generatedHex}
                onChange={handleChange}
              />
            </Grid>

            {/* Zoho Invoice Number */}
            <Grid item xs={12}>
              <TextField
                label="Zoho Invoice Number"
                variant="outlined"
                fullWidth
                name="zohoInvoiceNumber"
                value={invoiceData.zohoInvoiceNumber}
                onChange={handleChange}
              />
            </Grid>

            {/* Invoice Number */}
            <Grid item xs={12}>
              <TextField
                label="Invoice Number"
                variant="outlined"
                fullWidth
                name="invoiceNumber"
                value={invoiceData.invoiceNumber}
                onChange={handleChange}
              />
            </Grid>

            {/* Amount */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Amount"
                variant="outlined"
                fullWidth
                type="number"
                name="amount"
                value={invoiceData.amount}
                onChange={handleChange}
              />
            </Grid>

            {/* Invoice Amount */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Invoice Amount"
                variant="outlined"
                fullWidth
                type="number"
                name="invoiceAmount"
                value={invoiceData.invoiceAmount}
                onChange={handleChange}
              />
            </Grid>

            {/* Status */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  name="status"
                  value={invoiceData.status}
                  onChange={handleChange}
                  style={{ height: "40px" }}
                >
                  <MenuItem value="">Select</MenuItem>
                  <MenuItem value="overdue">Overdue</MenuItem>
                  <MenuItem value="paid">Paid</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <h3>Duration</h3>
            </Grid>

            {/* Plan Frequency */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Plan Frequency</InputLabel>
                <Select
                  label="Plan Frequency"
                  name="planFrequency"
                  value={invoiceData.duration.planFrequency}
                  onChange={handleChange}
                  style={{ height: "40px" }}
                >
                  <MenuItem value="">Select</MenuItem>
                  {planDuration.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* First Date */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Date"
                variant="outlined"
                fullWidth
                type="date"
                name="firstDate"
                value={invoiceData.duration.firstDate}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            {/* Last Date */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Date"
                variant="outlined"
                fullWidth
                type="date"
                name="lastDate"
                value={invoiceData.duration.lastDate}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            {/* Total Appliances */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Total Appliances"
                variant="outlined"
                fullWidth
                type="number"
                name="totalAppliances"
                value={invoiceData.duration.totalAppliances}
                onChange={handleChange}
              />
            </Grid>

            {/* Unused Days */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Unused Days"
                variant="outlined"
                fullWidth
                type="number"
                name="unusedDays"
                value={invoiceData.duration.unusedDays}
                onChange={handleChange}
              />
            </Grid>

            {/* Comments */}
            <Grid item xs={12}>
              <TextField
                label="Comments"
                variant="outlined"
                fullWidth
                name="comments"
                value={invoiceData.duration.comments}
                onChange={handleChange}
              />
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default MakeCreditNote;

// import { Button, Modal } from "@mui/material";
// import React, { useState } from "react";
// import { planDuration } from "../Data";
// import axios from "axios";
// import { serverUrl } from "../ApiUrl";
// import { notify, notifyFailed } from "../Common/Notification";

// const MakeCreditNote = ({ inv, sub }) => {
//   console.log(inv, "inv");
//   const [open, setOpen] = useState(false);
//   const [invoiceData, setInvoiceData] = useState({
//     name: inv.name.trim(),
//     phone: sub.phone,
//     generatedHex: inv.generatedHex,
//     zohoInvoiceNumber: inv.zohoInvoiceNumber,
//     invoiceNumber: inv.invoiceNumber,
//     amount: 0,
//     invoiceAmount: inv.actualTotalAmount,
//     status: "overdue",
//     duration: {
//       firstDate: "",
//       lastDate: "",
//       planFrequency: "",
//       totalAppliances: 0,
//       unusedDays: 0,
//       comments: "",
//     },
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     if (name in invoiceData.duration) {
//       setInvoiceData({
//         ...invoiceData,
//         duration: {
//           ...invoiceData.duration,
//           [name]: value,
//         },
//       });
//     } else {
//       setInvoiceData({
//         ...invoiceData,
//         [name]: value,
//       });
//     }
//   };
//   const handleSubmit = () => {
//     let data = {
//       ...invoiceData,
//       amount: Number(invoiceData.amount),
//       duration: {
//         ...invoiceData.duration,
//         totalAppliances: Number(invoiceData.duration.totalAppliances),
//         unusedDays: Number(invoiceData.duration.unusedDays),
//       },
//       invoiceAmount: Number(invoiceData.invoiceAmount),
//     };

//     axios
//       .post(`${serverUrl.u}/api/tele/registerCreditNotes`, data)
//       .then((res) => {
//         if (res.data.success) {
//           notify(res.data.message);
//         } else {
//           notifyFailed(res.data.message);
//         }
//       })
//       .catch((err) => {
//         let msg = err?.response?.data?.message
//           ? err?.response?.data?.message
//           : err?.message;
//         notifyFailed(msg);
//       });
//   };

//   return (
//     <>
//       <button className="btn btn-primary" onClick={() => setOpen(true)}>
//         Make Credit Note
//       </button>
//       <Modal
//         open={open}
//         onClose={() => setOpen(false)}
//         aria-labelledby="parent-modal-title"
//         aria-describedby="parent-modal-description"
//       >
//         <div
//           className="make_quto"
//           style={{
//             maxWidth: "400px",
//             margin: "0 auto",
//             padding: "20px",
//             fontFamily: "Arial, sans-serif",
//             backgroundColor: "white",
//           }}
//         >
//           <h2>Make Credit Note</h2>

//           <div>
//             <label>Name:</label>
//             <input
//               type="text"
//               name="name"
//               value={invoiceData.name}
//               onChange={handleChange}
//             />
//           </div>

//           <div>
//             <label>Phone:</label>
//             <input
//               type="text"
//               name="phone"
//               value={invoiceData.phone}
//               onChange={handleChange}
//             />
//           </div>

//           <div>
//             <label>Generated Hex:</label>
//             <input
//               type="text"
//               name="generatedHex"
//               value={invoiceData.generatedHex}
//               onChange={handleChange}
//             />
//           </div>

//           <div>
//             <label>Zoho Invoice Number:</label>
//             <input
//               type="text"
//               name="zohoInvoiceNumber"
//               value={invoiceData.zohoInvoiceNumber}
//               onChange={handleChange}
//             />
//           </div>

//           <div>
//             <label>Invoice Number:</label>
//             <input
//               type="text"
//               name="invoiceNumber"
//               value={invoiceData.invoiceNumber}
//               onChange={handleChange}
//             />
//           </div>

//           <div>
//             <label>Amount:</label>
//             <input
//               type="number"
//               name="amount"
//               value={invoiceData.amount}
//               onChange={handleChange}
//             />
//           </div>

//           <div>
//             <label>Invoice Amount:</label>
//             <input
//               type="number"
//               name="invoiceAmount"
//               value={invoiceData.invoiceAmount}
//               onChange={handleChange}
//             />
//           </div>

//           <div>
//             <label>Status:</label>
//             <select
//               type="text"
//               name="status"
//               value={invoiceData.status}
//               onChange={handleChange}
//             >
//               <option value={""}>select</option>
//               <option value={"overdue"}>overdue</option>
//               <option value={"paid"}>paid</option>
//             </select>
//           </div>

//           <h3>Duration</h3>

//           <div>
//             <label>Plan Frequency:</label>
//             <select
//               type="text"
//               name="planFrequency"
//               value={invoiceData.duration.planFrequency}
//               onChange={handleChange}
//             >
//               <option value={""}>select</option>
//               {planDuration.map((option) => (
//                 <option value={option.value}>{option.value}</option>
//               ))}
//             </select>
//           </div>

//           <div>
//             <label>First Date:</label>
//             <input
//               type="date"
//               name="firstDate"
//               value={invoiceData.duration.firstDate}
//               onChange={handleChange}
//             />
//           </div>

//           <div>
//             <label>Last Date:</label>
//             <input
//               type="date"
//               name="lastDate"
//               value={invoiceData.duration.lastDate}
//               onChange={handleChange}
//             />
//           </div>

//           <div>
//             <label>Total Appliances:</label>
//             <input
//               type="number"
//               name="totalAppliances"
//               value={invoiceData.duration.totalAppliances}
//               onChange={handleChange}
//             />
//           </div>

//           <div>
//             <label>Unused Days:</label>
//             <input
//               type="number"
//               name="unusedDays"
//               value={invoiceData.duration.unusedDays}
//               onChange={handleChange}
//             />
//           </div>

//           <div>
//             <label>Comments:</label>
//             <input
//               type="text"
//               name="comments"
//               value={invoiceData.duration.comments}
//               onChange={handleChange}
//             />
//           </div>
//           <Button variant="contained" onClick={() => handleSubmit()}>
//             Submit
//           </Button>
//         </div>
//       </Modal>
//     </>
//   );
// };

// export default MakeCreditNote;
