/** @format */
import axios from "axios";
import { serverUrl } from "../component/ApiUrl";


const axiosInstance = axios.create({});

export async function createPlan(data) {
	console.log("Inside create plan");
	try {
		const response = await axiosInstance({
			method: "POST",
			url: `${serverUrl.u}/v3/zoho/createPlan`,
			data: data ? data : null,
			headers: {
				"Content-Type": "application/json",
			},
		});
		console.log(response);
		return response;
	} catch (err) {
		console.log(err.message);
		console.log("Error in create plan");
	}
}

export async function fetchAllSubscriptions() {
	console.log("Inside fetch all subs..");
	try {
		const response = await axiosInstance({
			method: "GET",
			url: `${serverUrl.u}/v3/zoho//listAllPlan`,
			headers: {
				"Content-Type": "application/json",
			},
		});
		console.log(response);
		// console.log(response?.data?.data);
		return response;
	}
	catch (err) {
		console.log("Error in fetching all subs");
		console.log(err.message);
	}
}