import axios from "axios"
import { notify, notifyFailed } from "../Common/Notification"
import { loginName } from "../Common/constants"
import { subHistory } from "../Function/SubscriptionHistory"
import { serverUrl } from "../ApiUrl"
import store from "../Redux"


export const CheckClientIsOverdue = async (generatedHex) => {
   return await axios
        .post(`${serverUrl.u}/api/tele/getAllInvoicesDetails`, {
            generatedHex: generatedHex,
        })
        .then(function (res) {
            if (res.data.success === true) {
                for (let inv of res.data.data) {
                    let overdue = false
                    if (inv.status === "overdue" && inv.masterCancel===false) {
                        overdue = true
                    }
                    if(overdue===true){
                        return true
                    }
                }
                return false
            }
            else {
                return false
            }
        })
        .catch(function (err) {
            return false
        });

}


export const getHisab = async (app, installation, oldDrop, sub, planFrequency) => {
    let newApp = 0
    if (oldDrop == false) {
        let diff = Number(app)

        if (diff < 0) {
            newApp = diff
        } else if (diff > 0) {
            newApp = diff
        } else {
            newApp = diff
        }
    } else if (oldDrop === true) {
        console.log(app,sub.security,"fff");
        newApp = Number(app) 

    }

    return await axios.post(`${serverUrl.u}/api/tele/calculateClientAmount`, {
        "generatedHex": sub.generatedHex,
        "totalAppliances": oldDrop===false?newApp:newApp-sub.totalAppliances,
        "oldDrop": oldDrop,
        "installation": installation,
        "newPlanFrequency": planFrequency,
        "collectSecurity": oldDrop===false?true:(newApp-sub.totalAppliances>0?true:false),
        "orderType":sub.orderType,
    }).then(async function (res) {
        if (res.data.success === true) {
            notify("Details Fetch")
            return res.data.data
        } else {
            notifyFailed(res.data.message)
            return {}
        }
    }).catch(function (err) {
        notifyFailed("Error Occur")
        return {}
    })

}
export const handeleDownGrade = async (detail, sub) => {
    return await axios.post(`${serverUrl.u}/api/tele/downGradeSubscriptions`, detail).then(function (res) {
        notify(res.data.message)
        
        subHistory("Subscription DownGrade By telecallers", sub, "Subscription DownGrade", detail.downGrade)
        return res.data
    }).catch(function (err) {
        console.log(err);
    
        return {}
    })
}

export const handleUpgrade = async (detail, sub) => {

    return await axios.post(`${serverUrl.u}/api/tele/upgradeSubscriptions`, detail).then(function (res) {
        notify(res.data.message)
        subHistory("Subscription UpGrade By telecallers", sub, "Subscription UpGrade", detail.upGrade)
        return res.data
    }).catch(function (err) {
        console.log(err);
    
        return {}
    })
}

export const handleRate = (total) =>{

}

export const updateUpsellItem = async(sub,data,installationCharge,close)=>{
   await axios.post(`${serverUrl.u}/api/subscription/updateUpsell`,{
    generatedHex:sub.generatedHex,
    upsellItems:data,
    currentInstallation:installationCharge,
   }).then(function (res) {
       if(res.data.success){
         notify(res.data.message)
         subHistory("Upsell order detals are changed",{...sub,paylod:{data,installationCharge}},"Upsell Order Updated")
         close()
       }else{
        notifyFailed(res.data.message)
       }
   }).catch(function (err) {
      notifyFailed(err.response.data.message)
   })
}

export const reInstallation = async(sub,detail)=>{
   return await axios.post(`${serverUrl.u}/api/subscription/setReeInstallation`,{
        generatedHex:sub.generatedHex,
        InstallationDate:detail.InstallationDate,
        comments:`type-${detail.type} , comment:-${detail.comment}`,
        alisteUserId:store.getState().sar.data.user._id
    }).then((res)=>{
       return {res:res}
    }).catch((err)=>{
       return {err:err}
    })
}