import axios from "axios";

/**
 * Sends an API request using Axios and handles the response and errors.
 *
 * @async
 * @function apiRequest
 * @param {Object} [payload={}] - The configuration object for the API request.
 * @param {string} [payload.url=""] - The endpoint URL for the API request.
 * @param {string} [payload.method="GET"] - The HTTP method to use for the request (e.g., 'GET', 'POST').
 * @param {Object|null} [payload.data=null] - The request payload, used in methods like 'POST' or 'PUT'. Defaults to `null`.
 * @param {Object} [payload.headers={}] - Any additional headers to include in the request. Defaults to `{}`.
 * @param {string} [payload.successDefaultMessage=""] - The default message to return if the request is successful. Defaults to an empty string.
 *
 * @returns {Promise<Object>} - A promise that resolves to an object containing:
 *   - {boolean} success - Indicates whether the request was successful.
 *   - {Object} data - The response data from the server, or an empty object if the request failed.
 *   - {string} message - A message indicating the result of the request, either from the response or a default message.
 *
 * @throws {Error} Throws an error if the request fails and no response is available.
 */
export const apiRequest = async ({
  url = "",
  method = "GET",
  data = null,
  headers = {},
  successDefaultMessage = "Request Successful!",
  responseType = "",
} = {}) => {
  try {
    const response = await axios({
      url,
      method,
      data,
      headers,
      responseType,
    });

    const isSuccess = response.data?.success;
    const message =
      response.data?.message ||
      (isSuccess
        ? successDefaultMessage || "Operation Successfully"
        : response?.data?.data?.message || "Something Went Wrong!");

    return {
      success: isSuccess,
      data: response.data || {},
      message,
    };
  } catch (error) {
    const errorMessage =
      error.response?.data?.data?.error ||
      error.response?.data?.data?.message ||
      error.response?.data?.message ||
      error.message ||
      "Something Went Wrong!";

    console.log("{error}", error, errorMessage);
    return {
      success: false,
      data: {},
      message: errorMessage,
    };
  }
};
