import React, { useEffect, useState } from "react";
import {
  Box,
  Modal as MuiModal,
  Button,
  TextField,
  FormControl,
  Grid,
} from "@mui/material";

import ReactSelect from "react-select";

import { apiRequest } from "../../utils";
import { serverUrl } from "../ApiUrl";
import { notify, notifyFailed } from "../Common/Notification";

export const RegisterInvoice = ({ sub }) => {
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [plans, setPlans] = useState([]);
  const [planValue, setPlanValue] = useState("");
  const [invoiceStatusValue, setInvoiceStatusValue] = useState("");

  useEffect(() => {
    const getData = async () => {
      const resp = await apiRequest({
        url: `${serverUrl.sub}/v3/zoho/listAllPlan`,
        method: "GET",
      });

      if (!resp.success) {
        notifyFailed("Failed to get plans");
        return;
      }

      setPlans(resp.data.data.plans);
    };
    getData();
  }, []);

  const handleSubmit = async () => {
    const formData = {
      date: selectedDate,
      generatedHex: sub.generatedHex,
      planCode: planValue.value,
      invoiceStatus: invoiceStatusValue.value,
    };

    console.log("[formData]", formData);

    try {
      // API call here

      const response = await apiRequest({
        method: "POST",
        url: `${serverUrl.sub}/invoice/register`,
        data: formData,
      });

      console.log("[response invoice register]", response);
      if (!response.success) {
        notifyFailed(response.message);
        return;
      }

      notify(response.message || "Invoice Registered!!");
    } catch (error) {
      console.error("Error submitting data:", error);
      // Handle the error, e.g., show an error message
    }

    // Close the modal after submission
    setOpen(false);
  };

  const planOptions = plans.map((plan) => ({
    value: plan.plan_code,
    label: plan.name,
  }));
  return (
    <>
      <button className="btn btn-primary" onClick={() => setOpen(!open)}>
        Register Invoice
      </button>
      {open && (
        <MuiModal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 800,
              height: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              overflow: "scroll",
              p: 4,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h4>Register Invoice</h4>
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* Date Picker */}
                <TextField
                  label="Select Date"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={selectedDate || ""}
                  onChange={(e) => setSelectedDate(e.target.value)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* Dropdown 1 */}
                <FormControl fullWidth margin="normal">
                  <ReactSelect
                    value={planValue}
                    onChange={(e) => setPlanValue(e)}
                    options={planOptions}
                    placeholder="Select Plan"
                  />
                </FormControl>
              </Grid>
              {/* Dropdown 2 */}
              <Grid item xs={12}>
                <FormControl fullWidth margin="normal">
                  <ReactSelect
                    value={invoiceStatusValue}
                    onChange={(e) => setInvoiceStatusValue(e)}
                    options={[
                      { value: "overdue", label: "Overdue" },
                      { value: "paid", label: "Paid" },
                    ]}
                    placeholder="Select Invoice Status"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button onClick={() => setOpen(false)} color="secondary">
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  color="primary"
                  variant="contained"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        </MuiModal>
      )}
    </>
  );
};
