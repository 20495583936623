import { useEffect, useState } from "react";

import { Box, Button, Modal } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { apiRequest } from "../../../utils";
import { serverUrl } from "../../../component/ApiUrl";
import { notifyFailed } from "../../../component/Common/Notification";

export function TransactionForInvoice({ invoice }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstDate, setFirstDate] = useState("");
  const [lastDate, setLastDate] = useState("");
  const [open, setOpen] = useState(false);
  const [finalLedger, setFinalLedger] = useState([]);

  console.log("[invoice]", data.length);

  useEffect(() => {
    async function getData() {
      if (invoice === undefined) return;
      setLoading(true);
      const response = await apiRequest({
        url: `${serverUrl.sub}/v3/invoices/transaction`,
        method: "POST",
        data: {
          invoiceId: invoice.InvoiceId,
        },
      });

      if (!response.data.success) {
        notifyFailed(response.data.message);
        return;
      }
      if (!response.data.data.payment) {
        // notifyFailed("No Transactions");
        return;
      }
      setData(response.data.data.payment);
      setLoading(false);
    }

    getData();
  }, []);

  const columns =
    data.length > 0
      ? Object.keys(data[0]).map((header) => ({
          field: header,
        }))
      : [];

  return (
    <div>
      <Button variant="contained" onClick={() => setOpen(true)}>
        View Trnxs
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            maxHeight: 500,

            // maxWidth: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            overflow: "scroll",
            p: 4,
          }}
        >
          <div>
            <h4 style={{ marginBottom: "20px" }}>Transactions</h4>

            <div style={{ padding: 5, paddingTop: 10 }}>
              {data.length ? (
                <DataGrid
                  columns={columns}
                  rows={data.map((d) => ({
                    id: d?.referenceId,
                    ...d,
                  }))}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  slotProps={{
                    toolbar: {
                      csvOptions: { fileName: `Transactions` },
                    },
                  }}
                  pageSizeOptions={[10, 20, 30]}
                />
              ) : (
                <h4 style={{ textAlign: "center" }}>No Transactions</h4>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
