import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveData } from "./Redux/action";
import "./Top_Header.css";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { serverUrl } from "./ApiUrl";
import { Button } from "@mui/material";

export default function TopHeader(props) {
  const { Page_name } = props;
  let teleCallers = useSelector((state) => state.sar.data?.user?.first_name);
  let userRole = useSelector((state) => state.sar.data?.user?.roles);
  let [requiredUpdate, setRequiredUpdate] = useState(false);

  let navigate = useNavigate();
  let dispatch = useDispatch();
  if (teleCallers === undefined || userRole.length === 0) {
    navigate("/login");
    dispatch(saveData(""));
  }

  const v = 6.73;
  const getVersion = async () => {
    await axios
      .get(`${serverUrl.u}/api/version/getVersion`)
      .then(function (res) {
        if (res.data.success === true) {
          let data = res.data.data.existingversion[0].crmVersion;
          if (Number(data.version) > v) {
            if (data.required === true) {
              setRequiredUpdate(true);
            }
          }
        }
      });
  };

  useEffect(() => {
    const intervalCall = setInterval(() => {
      getVersion();
    }, 100000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, []);
  return (
    <div className="top-header-container">
      <ToastContainer />
      <div className="top-logo-container-main">
        <div className="top-logo-container">
          <div style={{ display: "flex", alignItems: "center" }}>
            {" "}
            <img
              className="logo-img"
              src={process.env.PUBLIC_URL + "Aliste Logo.png"}
              alt="company-logo"
            />
          </div>
          <div
            style={{
              marginLeft: "6%",
              display: "flex",
              flexDirection: "column",
              height: "40px",
            }}
          >
            <div className="logo-name">Aliste</div>
            <div className="logo-slaes">Sales</div>
          </div>
        </div>
      </div>
      <div className="top-side">
        <div className="top-header-rightSide">
          <div className="col name">
            <div className="col">
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <img className='top-header-icon' src={process.env.PUBLIC_URL+`${url_img}`} alt="logo"/> */}
                <span style={{ fontSize: "18px", fontWeight: "400" }}>
                  {Page_name}
                </span>
              </div>
            </div>
          </div>
          <div className="col">
            <div
              style={{ float: "right", display: "flex", alignItems: "center" }}
            >
              <div className="top-header-Name-version">
                <span>
                  Welcome, <strong>{teleCallers}</strong>{" "}
                </span>
                <span
                  style={{
                    color: "#91A3B0",
                    marginLeft: "5px",
                    textAlign: "right",
                    marginTop: "-3px",
                  }}
                >
                  Version: {v}
                </span>
              </div>
              {/* {
                   userRole.includes(user_roles.LeadHandler) && <AddTeleCallertoAutoAssign/>
                } */}
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  localStorage.removeItem("userProfile");
                  dispatch(saveData(""));
                  navigate("/login");
                }}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
      {requiredUpdate && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "whitesmoke",
              borderRadius: "12px",
              padding: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div className="mb-2">
              Your are running on older version click on button to update
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => window.location.reload()}
              >
                Update Portal
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
