import React, { useState, useRef, useEffect, memo } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { orderTyepes, state, user_roles } from "../Data";
import City from "./City";
import { addGrevianceClient } from "../ApiCalls/AddGreviance";
import { CSVLink } from "react-csv";
import { smartMeterOrderTypes } from "../Function/data";
function Installationtablewise({
  details,
  array,
  SetWebsiteReload,
  websiteReload,
  userName,
  colorDecide,
  notify,
  salesUserDatas,
  index,
  search,
  avilableElectrician,
}) {
  let loginName = useSelector((state) => state.sar.data.user.first_name);
  let userRoles = useSelector((state) => state.sar.data.user.roles);
  let [gerivanceDate, setGrivanceDate] = useState();
  let [grivanceDisplay, setGrivanceDisplay] = useState(false);
  let [update, setUpdateData] = useState([]);
  let [GerivanceType, setGrivanceType] = useState("Misc");
  let [deviceObject, setDeviceObject] = useState({});
  let [teleCallersChanege, setTeleCallerChange] = useState("All");
  let [electricChanege, setElectricChange] = useState("All");
  let [cityChange, setCityChange] = useState("All");
  let grivance = useRef(null);
  let grivanceComment = useRef(null);
  let [data, setData] = useState([]);
  let [cityData, setCityData] = useState([]);
  let [csv, setCsv] = useState([]);
  useEffect(() => {
    let filterData = details;

    if (teleCallersChanege !== "All") {
      if (teleCallersChanege === "All") {
        filterData = details;
      } else {
        filterData = details.filter(
          (e) => e.teleCallerId === teleCallersChanege
        );
      }
    }

    if (electricChanege !== "All") {
      if (electricChanege === "All") {
        filterData = details;
      } else {
        filterData = details.filter(
          (e) => e.AssignedElectritian === electricChanege
        );
      }
    }

    if (cityChange !== "All") {
      if (cityChange === "All") {
        filterData = details;
      } else {
        filterData = details.filter((e) => e.city === cityChange);
      }
    }
    if (
      cityChange === "All" &&
      teleCallersChanege === "All" &&
      electricChanege === "All"
    ) {
      filterData = details;
    }
    let dataDate = filterData.filter((e) => {
      if (
        moment.utc(e.InstallationDate).format("ddd -  Do MMM") ==
        moment.utc(array).format("ddd -  Do MMM")
      ) {
        return e;
      }
    });
    setData(dataDate);
    let cityArray = [];
    let csvData = [];
    for (let ele of dataDate) {
      let city = ele.city.toUpperCase();
      if (cityArray.includes(city)) {
      } else {
        if (city !== "NEW DELHI") {
          cityArray.push(city);
        } else if (city === "NEW DELHI") {
          if (!cityArray.includes("DELHI")) {
            cityArray.push("DELHI");
          }
        }
      }
      let dE = [];
      if (Array.isArray(ele.AssignedElectritian)) {
        for (let name of ele.AssignedElectritian) {
          if (typeof name === "object" && name !== null) {
            dE.push(name.electricianName);
          } else {
            dE.push(name);
          }
        }
      } else {
        dE.push(ele.AssignedElectritian);
      }
      let product = [];
      if (ele.orderType === orderTyepes[2].value) {
        for (let p of ele.upsellItems) {
          let obj = {
            "Product Name": p.productName,
            Qty: p.qty,
          };
          product.push(obj);
        }
      }

      let obj = {
        Name: ele.name,
        Electrician: JSON.stringify(dE),
        Type: ele.orderType,
        City: ele.city,
        Address: ele.address,
        "Installation Date": moment
          .utc(ele.InstallationDate)
          .format("ddd -  Do MMM"),
        "Admin Comment": ele.headComments,
        "Sales Agent Comment": ele.comments,
        "Electrician Comments": ele.installerComments,
        "Grievence Comments": ele.commentByGrivienceDepartment,
        TeleCaller: ele.teleCallerId,
        "Second TeleCaller": ele.secondarySalesPerson,
        Appliances:
          ele.orderType !== orderTyepes[2].value
            ? ele.totalAppliances
            : JSON.stringify(product),
      };
      csvData.push(obj);
    }
    setCsv(csvData);
    console.log(csvData, "city array");
    setCityData(cityArray);
  }, [details, teleCallersChanege, electricChanege, cityChange, array]);

  return (
    <div key={index + "a"}>
      {cityData.length !== 0 ? (
        <>
          <div className="table-date-container">
            {" "}
            <div className="table-date">
              {moment.utc(array).format("ddd -  Do MMM")}
            </div>
            <button
              className="btn btn-primary mobile-btn"
              onClick={() => {
                setGrivanceDate(cityData[0].InstallationDate);
                setGrivanceDisplay(!grivanceDisplay);
              }}
            >
              Add Grievance Site
            </button>
            <CSVLink
              className="btn btn-primary"
              filename={`${moment.utc(array).format("ddd_Do_MMM")}.csv`}
              style={{ marginLeft: "2%" }}
              data={csv}
            >
              Download CSV
            </CSVLink>
          </div>

          {/* Filers */}

          {userRoles?.includes("Telecaller") ? null : (
            <div
              className="row"
              style={{ marginLeft: "0vw", marginBottom: "0.7vw" }}
            >
              <div className="col-4 col-md-2">
                <label className="create-label1">TeleCaller</label>
                <div class="input-group ">
                  <select
                    class="custom-select height-select"
                    onChange={(e) => {
                      setTeleCallerChange(e.target.value);
                      setCityChange("All");
                      setElectricChange("All");
                    }}
                    value={teleCallersChanege}
                  >
                    <option value="All">All</option>
                    {salesUserDatas?.map((option, optionIndex) => (
                      <option key={optionIndex} value={option.first_name}>
                        {option.first_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-4 col-md-2">
                <label className="create-label1">City</label>
                <div class="input-group ">
                  <select
                    class="custom-select height-select"
                    onChange={(e) => {
                      setCityChange(e.target.value);
                      setTeleCallerChange("All");
                      setElectricChange("All");
                    }}
                    value={cityChange}
                  >
                    <option value="All">All</option>
                    <option value="DELHI">DELHI</option>
                    {state?.map((option, optionIndex) => (
                      <option key={optionIndex} value={option.value}>
                        {option.value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-4 col-md-2">
                <label className="create-label1">Electrician</label>
                <div class="input-group ">
                  <select
                    class="custom-select height-select"
                    onChange={(e) => {
                      setElectricChange(e.target.value);
                      setTeleCallerChange("All");
                      setCityChange("All");
                    }}
                    defaultValue="All"
                  >
                    <option value="All">All</option>
                    <option value="empty">empty</option>
                    {userName?.map((option, optionIndex) => (
                      <option key={optionIndex} value={option.first_name}>
                        {option.first_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}

          <div
            className="table-container1"
            style={{
              height: "fit-content",
              marginTop: 0,
              marginLeft: 0,
            }}
          >
            <table
              className="table  table-sm table-bordered "
              key={index + "e"}
            >
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>
                    Assigned Electritian
                    <span style={{ color: "red" }}>*</span>
                  </th>
                  <th>
                    Un-Assigned Electritian
                    <span style={{ color: "red" }}>*</span>
                  </th>
                  <th>Order Type</th>
                  <th style={{ width: "110px" }}>Electrician History</th>
                  <th scope="col" style={{ width: "100px" }}>
                    Name
                    <span style={{ color: "red" }}>*</span>
                  </th>

                  <th scope="col" style={{ width: "100px" }}>
                    Reinstallation Site Number
                    <span style={{ color: "red" }}>*</span>
                  </th>
                  {userRoles.includes("Super Admin") ? (
                    <>
                      <th style={{ width: "180px" }}>
                        Admin Comment <span style={{ color: "red" }}>*</span>
                      </th>
                    </>
                  ) : null}
                  {/* <th>Client Status</th> */}
                  {loginName === "Vikas" || loginName === "Sidharth" ? (
                    <th>Uninstall</th>
                  ) : null}
                  <th scope="col">
                    Address
                    <span style={{ color: "red" }}>*</span>
                  </th>
                  <th scope="col">
                    Sales Agent Comment
                    <span style={{ color: "red" }}>*</span>
                  </th>
                  <th>Location</th>
                  <th scope="col">
                    City
                    <span style={{ color: "red" }}>*</span>
                  </th>
                  {/* <th>Incentive Zone</th> */}
                  <th>Sales Agent</th>
                  <th>Second Sales Agent</th>
                  <th>Total Appliances/Meters</th>
                  <th>Status</th>
                  <th scope="col" style={{ width: "110px" }}>
                    Phone
                  </th>
                  <th scope="col">
                    Installation Date
                    <span style={{ color: "red" }}>*</span>
                  </th>
                  <th>Partial Payments</th>
                  <th>Gerivance Comment</th>
                  <th>Grivience Handler</th>
                  <th>Greviance Comment Dep</th>

                  <th>Electrician Comment</th>

                  <th>Site Visit</th>
                  {/* {!smartMeterOrderTypes.includes[e.orderType] && <th style={{ width: "160px" }}>Site Visit Set</th> } */}
                  <th style={{ width: "160px" }}>Site Visit Set</th>
                  {userRoles.includes("Super Admin") ? (
                    <>
                      <th>Payment Link HandOver</th>
                      <th style={{ width: "260px" }}>
                        Convert Subscription Type
                      </th>
                      <th style={{ width: "80px" }}>Site Done</th>

                      <th style={{ width: "180px" }}>Uninstall Done</th>
                    </>
                  ) : null}
                  {!userRoles.includes(user_roles.Isp) ? (
                    <>
                      {/* <th style={{ width: "280px" }}>Update Zoho</th> */}
                      {/* <th style={{ width: "300px" }}>Razorpay Payment</th> */}
                    </>
                  ) : null}

                  {loginName === "Udit" ||
                  loginName === "BHAVYA" ||
                  loginName === "Konark" ||
                  loginName === "Akhil" ||
                  loginName === "Digvijay" ? (
                    <>
                      <th style={{ width: "130px" }}>Revoke ReInstallation</th>
                      <th style={{ width: "130px" }}>Force Site Done</th>
                      <th style={{ width: "180px" }}>Delete And Drop</th>
                      <th style={{ width: "240px" }}>
                        Change Subscription Stage
                      </th>
                      <th>Outside NCR</th>
                      <th>Days</th>
                    </>
                  ) : null}

                  {!userRoles.includes(user_roles.Isp) && (
                    <th style={{ width: "140px" }}>Greviance Action</th>
                  )}
                  <th>Hex</th>
                </tr>
              </thead>

              <tbody key={index + "f"}>
                {cityData.length !== 0
                  ? cityData.map((ele, newIndex) => {
                      let cityDataNew = data.filter((e) => {
                        let c =
                          e.city.toUpperCase() === "NEW DELHI"
                            ? "DELHI"
                            : e.city.toUpperCase();
                        if (c == ele) {
                          console.log("====================================");
                          console.log(e.name, "dfdvdc");
                          console.log("====================================");
                          return e;
                        }
                      });
                      return (
                        <React.Fragment key={newIndex + "h"}>
                          <tr key={newIndex + "b"}>
                            <td>{ele}</td>
                          </tr>
                          {search(cityDataNew)
                            .sort(function (a, b) {
                              if (a.zone < b.zone) {
                                return -1;
                              }
                              if (a.zone > b.zone) {
                                return 1;
                              }
                              return 0;
                            })
                            .map((e, i) => {
                              return (
                                <City
                                  e={e}
                                  i={i}
                                  SetWebsiteReload={SetWebsiteReload}
                                  websiteReload={websiteReload}
                                  userName={userName}
                                  colorDecide={colorDecide}
                                  notify={notify}
                                  avilableElectrician={avilableElectrician}
                                />
                              );
                            })}
                        </React.Fragment>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </>
      ) : null}
      {grivanceDisplay === true ? (
        <div className="greivance-container" style={{ top: "21% !important" }}>
          <span
            className="cross"
            onClick={() => setGrivanceDisplay(!grivanceDisplay)}
          >
            x
          </span>
          <div class="input-group mb-1">
            <input
              type="text"
              ref={grivance}
              placeholder="Enter Hex Code here..."
              defaultValue={update.generatedHex}
            />
          </div>

          <div class="input-group mb-1">
            <input
              type="text"
              ref={grivanceComment}
              placeholder="Enter Comment here..."
              defaultValue=""
            />
          </div>
          <div class="input-group mb-1">
            <label>Problem Type</label>
            <select
              onChange={(e) => setGrivanceType(e.target.value)}
              defaultValue={GerivanceType}
            >
              <option value="WiFi">WiFi</option>
              <option value="Hanging">Hanging</option>
              <option value="Power">Power</option>
              <option value="Switch">Switch</option>
              <option value="Misc">Misc</option>
            </select>
          </div>
          <div class="input-group mb-1">
            <label>If you want to send devices select Yes</label>
            <select
              onChange={(e) =>
                setDeviceObject({
                  ...deviceObject,
                  decision: e.target.value,
                })
              }
              defaultValue="no"
            >
              <option value="no">No</option>
              <option value="yes">Yes</option>
            </select>
          </div>
          {deviceObject?.decision == "yes" ? (
            <div className="DeviceObjectDetails">
              <div className="DeviceObjectDetails-specific">
                <label>One Switch</label>
                <input
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) =>
                    setDeviceObject({
                      ...deviceObject,
                      one: e.target.value,
                    })
                  }
                />
              </div>
              <div className="DeviceObjectDetails-specific">
                <label>Two Switch</label>
                <input
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) =>
                    setDeviceObject({
                      ...deviceObject,
                      two: e.target.value,
                    })
                  }
                />
              </div>
              <div className="DeviceObjectDetails-specific">
                <label>Three Switch</label>
                <input
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) =>
                    setDeviceObject({
                      ...deviceObject,
                      three: e.target.value,
                    })
                  }
                />
              </div>
              <div className="DeviceObjectDetails-specific">
                <label>4 + 1 Switch</label>
                <input
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) =>
                    setDeviceObject({
                      ...deviceObject,
                      four: e.target.value,
                    })
                  }
                />
              </div>
              <div className="DeviceObjectDetails-specific">
                <label>Five Switch</label>
                <input
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) =>
                    setDeviceObject({
                      ...deviceObject,
                      five: e.target.value,
                    })
                  }
                />
              </div>
              <div className="DeviceObjectDetails-specific">
                <label>IR</label>
                <input
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) =>
                    setDeviceObject({
                      ...deviceObject,
                      ir: e.target.value,
                    })
                  }
                />
              </div>
              <div className="DeviceObjectDetails-specific">
                <label>Fan</label>
                <input
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) =>
                    setDeviceObject({
                      ...deviceObject,
                      fan: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          ) : null}
          <button
            className="btn btn-primary"
            onClick={() =>
              addGrevianceClient(
                grivance.current.value,
                deviceObject,
                `${grivanceComment.current.value} - ${moment(new Date()).format(
                  "DD-MM-YYYY"
                )}`,
                GerivanceType,
                loginName,
                gerivanceDate,
                notify,
                SetWebsiteReload,
                websiteReload,
                setGrivanceDisplay,
                {}
              )
            }
          >
            Greviane
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default Installationtablewise;
