import React, { useState, useRef } from "react";
import axios from "axios";
import { pages, serverUrl } from "../ApiUrl";
import AssignElectrician from "./AssignElectrician";
import AddressUpdate from "../Function/AddressUpdate";
import CommentUdate from "../Function/CommentUdate";
import { updateInstalledtwoHouse } from "../Function/AxiosControl";
import ChangeInstallationDate from "../Function/ChangeInstallationDate";
import PlanUpdate from "../Function/PlanUpdate";
import { finalSelectDeviceUpdate, orderTyepes, user_roles } from "../Data";
import { changeInstallationDate } from "../ApiCalls/installationDate";
import { removeInstallationData } from "../Redux/installationActions";
import CityUpdate from "../Function/CityUpdate";
import CollectRazorpayRemaning from "../Function/CollectRazorpayRemaning";
import { useSelector } from "react-redux";
import OutOfNCR from "./OutOfNCR";
import Deleteanddrop from "../Function/Deleteanddrop";
import { useDispatch } from "react-redux";
import UninstallDoneBtn from "../SpecificElectricianUpGradeDownGrade.js/UninstallDoneBtn";
import { subHistory } from "../Function/SubscriptionHistory";
import SendToAddressLayout from "./SendToAddressLayout";
import WithOutCountFinalInstall from "../Final_Installed/WithOutCountFinalInstall";
import UnAssignElectrician from "./UnAssignElectrician";
import SubscriptionZoneUpdate from "../SpecificElectricianUpGradeDownGrade.js/SubscriptionZoneUpdate";
import { daysDifference } from "../Common/applianceDifference";
import SiteVisitCreated from "../Address&Layout/SiteVisitCreated";
import {
  installationDoneByElectrician,
  removeElectricianFromSite,
} from "../SpecificElectricianUpGradeDownGrade.js/UpgradeDownGrade/ApplincesDifferenceApi";
import ChangeHyperLink from "../Function/ChangeHyperLink";
import AssignAvailableElectricna from "./AssignAvailableElectricna";
import RevokeReInstallation from "../SearchClient/RevokeReInstallation";
import AdminComment from "./AdminComment";
import PaymentLinkHeadOver from "./PaymentLinkHeadOver";
import ConvertSiteVisitToOther from "../SiteVisit/ConvertSiteVisitToOther";
import GrivanceHistory from "../OnCallGrivance/GrivanceHistory";
import ReinstallationSiteNumber from "./ReinstallationSiteNumber";
import { smartMeterOrderTypes } from "../Function/data";

export default function City({
  e,
  i,
  SetWebsiteReload,
  websiteReload,
  userName,
  colorDecide,
  notify,
  avilableElectrician,
}) {
  let userRole = useSelector((state) => state.sar.data?.user?.roles);
  let loginName = useSelector((state) => state.sar.data.user.first_name);
  let [intallDone, setInstallDone] = useState(false);
  let [update, setUpdateData] = useState([]);
  let installDeviceId = useRef(null);
  let typeOfDevice = useRef(null);
  const dispatch = useDispatch();

  const updateInstalledtwoHouses = async (data) => {
    if (data.zone === undefined || data.zone === "") {
      notify("Plz Update Incentive Zone");
      return;
    }

    axios
      .post(`${serverUrl.u}/api/tele/countApplianceInHouse`, {
        houseId: data.houseId,
        generatedHex: data.generatedHex,
      })
      .then(async function (res) {
        if (res.data.success === true) {
          subHistory(
            `Appliance Difference subscription appliance ${data.totalAppliances} and house appliance Diff ${res.data.newAppliances} .`,
            data,
            "Appliance Difference",
            `${res.data.newAppliances}`
          );

          if (res.data.newAppliances === 0) {
            notify(`Appliances difference is ${res.data.newAppliances}`);
            await installationDoneByElectrician(
              data,
              true,
              data.orderType === orderTyepes[2].value ? true : false,
              pages.installation
            );
            dispatch(removeInstallationData(data));
          } else {
            notify(`Appliances difference is ${res.data.newAppliances}`);
          }
        } else {
          notify(res.data.message);
        }
      })
      .catch(function (err) {
        console.log(err);
        notify("House Not found");
      });
  };
  const updateInstallDone = async (device, data, type) => {
    if (data.zone === undefined || data.zone === "") {
      notify("Plz Update Incentive Zone");
      return;
    }
    axios
      .post(`${serverUrl.u}/api/dispatch/countTotalNumberOfDevice`, {
        generatedHex: data.generatedHex,
        deviceId: device,
        type: type,
      })
      .then(async function (res) {
        if (res.data.success === true) {
          if (res.data.newAppliances === 0) {
            notify(`Appliances difference is ${res.data.newAppliances}`);
            await installationDoneByElectrician(
              data,
              true,
              data.orderType === orderTyepes[2].value ? true : false,
              pages.installation
            );
            dispatch(removeInstallationData(data));
          } else {
            notify(`Appliances difference is ${res.data.newAppliances}`);
          }
        } else notify("Appliance difference have problem");
      })
      .catch(function (err) {
        console.log(err);
        notify(err.response.data.message);
        notify("House Not Found");
      });
    const installDate = new Date();
    const done = async () => {
      await axios
        .post(`${serverUrl.u}/api/dispatch/updateInstalledtwo`, {
          generatedHex: data.generatedHex,
          deviceId: device,
          type: type,
          firstTime: data.finalInstalled === false ? true : false,
        })
        .then(function (res) {
          if (res.data.success === true) {
            setInstallDone(false);
            notify("Data Saved Successfully");
            dispatch(removeInstallationData(data));
            if (data.finalInstalled === false) {
              subHistory(
                "First Installation Complete",
                data,
                "First Installation Complete",
                ""
              );
            }
            changeInstallationDate(
              installDate,
              data,
              loginName,
              SetWebsiteReload,
              websiteReload,
              notify
            );
          } else {
            setInstallDone(true);
            notify(res.data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
          notify(error.message);
        });
    };
  };
  return (
    <React.Fragment key={i + "g"}>
      <tr
        key={i + "c"}
        style={{
          backgroundColor:
            e.toBePickedUp === true
              ? "rgba(255, 255, 0, 0.48)"
              : e.grivience === true
              ? "rgb(235 85 82 / 30%)"
              : e.halfInstallation === true
              ? "lightgreen"
              : e.toBePickedUp === true
              ? "#ffff007a"
              : "",
        }}
      >
        <td>{i + 1}</td>
        <AssignAvailableElectricna
          e={e}
          userName={userName}
          notify={notify}
          avilableElectrician={avilableElectrician}
        />
        <UnAssignElectrician e={e} notify={notify} />
        <td>{e.orderType}</td>
        <td>
          <div className="hover-wide">
            <ul style={{ paddingLeft: "10px", marginBottom: "4px" }}>
              {e.AssignedElectritianList &&
                e?.AssignedElectritianList?.map((ele, electric) => {
                  return (
                    <li key={electric + "d"}>
                      {ele?.electricianName ? ele?.electricianName : "not"}
                    </li>
                  );
                })}
            </ul>
          </div>
        </td>
        <td
          label="Name"
          style={{ fontWeight: "bold", cursor: "pointer" }}
          onClick={() => {
            navigator.clipboard.writeText(e.generatedHex);
          }}
        >
          {e?.name}
        </td>
        <ReinstallationSiteNumber e={e} page={pages.installation} />
        {userRole.includes("Super Admin") ? (
          <>
            <td>
              <AdminComment sub={e} page={pages.installation} />
            </td>
          </>
        ) : null}

        {loginName === "Vikas" || loginName === "Sidharth" ? (
          <td>{e?.toBePickedUp === true ? "Uninstall Set" : ""}</td>
        ) : null}

        <AddressUpdate e={e} page={pages.installation} />
        <CommentUdate e={e} page={pages.installation} />
        <td>
          <ChangeHyperLink e={e} page={pages.installation} />
        </td>
        <CityUpdate
          e={e}
          SetWebsiteReload={SetWebsiteReload}
          websiteReload={websiteReload}
          page={pages.installation}
        />
        {/* <td>
                    {e.zone === undefined ||
                    e.zone === "" ||
                    e.zone === "Select Zone" ? (
                        <SubscriptionZoneUpdate
                            subscription={e}
                            notify={notify}
                            page={pages.installation}
                        />
                    ) : (
                        e.zone
                    )}
                </td> */}
        <td label="Sales Agent">{e.teleCallerId}</td>
        <td>{e.secondarySalesPerson}</td>
        <td>
          {e.orderType !== orderTyepes[2].value ? (
            e.totalAppliances
          ) : (
            <ul style={{ paddingInlineStart: "12px" }}>
              {e.upsellItems.map((product, i) => {
                return (
                  <li>
                    Product Name - {product.productName},
                    {/* Selling Per Pic - {product.sellingPricePerPic}, */}
                    Qty - {product.qty}
                  </li>
                );
              })}
            </ul>
          )}
        </td>

        <td label="Status">
          <div
            className="hover-wide"
            style={{ color: colorDecide(e.subscriptionStage) }}
          >
            {e.grivience === true ? "Grivience & " : ""}
            {e.subscriptionStage}
            {e.pickedForInstallation === true ? "  &  Out for Delivery" : ""}
          </div>
        </td>
        <td label="Phone">
          <a href={`tel:${e.phone}`}>{e.phone}</a>
        </td>

        <ChangeInstallationDate
          e={e}
          setReload={SetWebsiteReload}
          reload={websiteReload}
          notify={notify}
          decision={"td"}
          page={pages.installation}
        />

        <td
          label="Partial Payment"
          style={{
            color: `${
              e.partialPayment === false
                ? "green"
                : e.partialAmount === 0
                ? "red"
                : "orange"
            }`,
          }}
        >
          {e.partialPayment === false
            ? "Paid"
            : e.partialAmount === 0
            ? "Zero"
            : "Partial"}
        </td>

        <td>
          <div className="hover-wide">{e.extra2}</div>
        </td>
        <td>{e.grivienceHandler}</td>
        <td>
          <div className="hover-wide">{e.commentByGrivienceDepartment}</div>
        </td>

        <td label="Electrician Comment">
          <div className="hover-wide">{e.installerComments}</div>
        </td>

        <td>
          {e.siteVisiteByElectrician === true ? "Site Visit Set" : "Not Set"}
        </td>
        {!smartMeterOrderTypes.includes[e.orderType] ? (
          <td>
            <SiteVisitCreated
              sub={e}
              page={pages.installation}
              btnName={"Site Visit Create"}
              text={"Are you sure you mark this subcription as site visit ?"}
            />
          </td>
        ) : (
          <td>-</td>
        )}
        {userRole.includes("Super Admin") ? (
          <>
            <td>
              {!smartMeterOrderTypes.includes[e.orderType] ? (
                <PaymentLinkHeadOver sub={e} />
              ) : (
                "-"
              )}
            </td>
            <td>
              {!smartMeterOrderTypes.includes[e.orderType] ? (
                <ConvertSiteVisitToOther
                  sub={e}
                  reload={websiteReload}
                  setReload={SetWebsiteReload}
                  page={pages.installation}
                />
              ) : (
                "-"
              )}
            </td>
            <td label="Action">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  lineHeight: 0,
                  justifyContent: "space-evenly",
                }}
              >
                <img
                  data-tip="Installed"
                  data-place="bottom"
                  onClick={() => {
                    if (e.partialPayment === false) {
                      if (e.houseId === "") {
                        setInstallDone(!intallDone);
                        setUpdateData(e);
                      } else {
                        updateInstalledtwoHouses(e);
                      }
                    } else {
                      notify("This Client is not a Paid Client");
                    }
                  }}
                  style={{ width: "16px", height: "16px" }}
                  src={process.env.PUBLIC_URL + "/assets/icon/readyPacket.png"}
                  alt="icon"
                />
              </div>
            </td>

            <td>
              {e?.toBePickedUp === true ? (
                <UninstallDoneBtn
                  data={e}
                  notify={notify}
                  setReload={SetWebsiteReload}
                  reload={websiteReload}
                  page={pages.installation}
                />
              ) : (
                "Active"
              )}
            </td>
          </>
        ) : null}

        {!userRole.includes(user_roles.Isp) ? (
          <>
            {/* <td>{e.partialPayment === true ? <PlanUpdate e={e} /> : ""}</td> */}
            {/* <td>{e.partialPayment === true ? <CollectRazorpayRemaning e={e} /> : ""}</td> */}
          </>
        ) : null}

        {loginName === "Udit" ||
        loginName === "BHAVYA" ||
        loginName === "Konark" ||
        loginName === "Akhil" ||
        loginName === "Digvijay" ? (
          <>
            {e.reeInstallation ? <td>Re-Installation Site</td> : <td></td>}
            <WithOutCountFinalInstall data={e} notify={notify} />
            <td>
              <Deleteanddrop
                e={e._id}
                data={e}
                reloadW={websiteReload}
                setReload={SetWebsiteReload}
                notify={notify}
                page={pages.installation}
              />
            </td>
            <td>
              <SendToAddressLayout data={e} notify={notify} />
            </td>
            <td>
              <OutOfNCR dataSub={e} notify={notify} />
            </td>
            <td>
              <div
                style={{
                  backgroundColor: daysDifference(e.createdAt)[0],
                  width: "fit-content",
                  padding: "5px 10px",
                  borderRadius: "70%",
                }}
              >
                {daysDifference(e.createdAt)[1]}
              </div>
            </td>
          </>
        ) : null}
        {!userRole.includes(user_roles.Isp) && (
          <td>
            <GrivanceHistory data={e} notify={notify} />
          </td>
        )}
        <td>{e.generatedHex}</td>
      </tr>

      {intallDone === true ? (
        <div className="reson-form">
          <span
            className="reson-cross"
            onClick={() => setInstallDone(!intallDone)}
          >
            x
          </span>

          <div>
            <label className="specific-label">Enter one Device Id</label>
            <div className="input-group">
              <select
                class="custom-select height-select mb-2"
                ref={typeOfDevice}
                defaultValue={finalSelectDeviceUpdate[0].value}
              >
                {finalSelectDeviceUpdate.map((option) => (
                  <option value={option.value}>{option.value}</option>
                ))}
              </select>
            </div>
            <div className="input-group">
              <input
                type="text"
                ref={installDeviceId}
                className="form-control"
                required
                aria-label="referenceId"
                aria-describedby="basic-addon1"
              />
            </div>
            <button
              className="btn2 btn-primary mt-2"
              onClick={() =>
                updateInstallDone(
                  installDeviceId.current.value,
                  update,
                  typeOfDevice.current.value
                )
              }
            >
              Done
            </button>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
}
