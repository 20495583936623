import axios from "axios";
import { subHistory } from "../Function/SubscriptionHistory";
import { notify, notifyFailed } from "./Notification";
import { serverUrl } from "../ApiUrl";
import store from '../Redux/index'
import { async } from "q";
import { compareFrequency, planCost } from "../Data";
import moment from "moment";
import { getSubscriptionDataReturn, installationDoneByElectrician, removeElectricianFromSite } from "../SpecificElectricianUpGradeDownGrade.js/UpgradeDownGrade/ApplincesDifferenceApi";

export const applianceDifferenceWithDeviceId = async (dataObject, deviceData, setDifferenceAppliancesText, setPaymentLink, setWhatappNumber, setBtnText, setReload, reload) => {
  await axios.post(`${serverUrl.u}/api/dispatch/countTotalNumberOfDevice`, {
    generatedHex: dataObject.generatedHex,
    deviceId: deviceData.deviceId,
    type: deviceData.deviceType,
  }).then(async function (res) {
    if (res.data.success === true) {
      subHistory(`Appliance Difference subscription appliance ${dataObject.totalAppliances} and house appliance Diff ${res.data.newAppliances} .`, dataObject, "Appliance Difference",  `${res.data.newAppliances}`)
      if (res.data.totalAppliances > 4 && dataObject.teleCallerId==="NetWay") {
        await extraAppliances(dataObject, res.data.totalAppliances - 4)
      } else if(res.data.totalAppliances>0 && dataObject.teleCallerId!=="NetWay"){
        // await freeAppliance(dataObject, res.data.totalAppliances,false)
      }else {
        notify("All payment done")
        setDifferenceAppliancesText("All Payment Done Click on Final Installed")
      }
      if(res.data.totalAppliances>0 && dataObject.teleCallerId==="NetWay"){
      // await freeAppliance(dataObject, res.data.totalAppliances,true)
      }

    } else {
      notifyFailed(res.data.message)
      setBtnText("Failed")
    }
  }).catch(function (err) {
    console.log(err);
    setBtnText("Failed")
    notifyFailed(err.message)
  })
  setBtnText("Done")
  setReload(!reload)
}
export const applianceDifferenceWithHouseId = async (dataObject, deviceData, setDifferenceAppliancesText,setPaymentLink,setWhatappNumber, setBtnText, setReload, reload) => {
  setBtnText('loading...')
  await axios.post(`${serverUrl.u}/api/tele/countApplianceInHouse`, {
    houseId: dataObject.houseId,
    generatedHex: dataObject.generatedHex
  }).then(async function (res) {
    if (res.data.success === true) {
    let latestSub = await getSubscriptionDataReturn(dataObject)
    if(latestSub.partialPayment===false){
      notify("All Payment Done")
      setBtnText('Installation Done')
      return
    }
    if(res.data.newAppliances===0) {
      notify("There is no unpaid  appliances")
      setBtnText('Installation Done')
      return
    }
     await updateSirt(dataObject,res.data.newAppliances).then(async(ress)=>{
       if(ress){
        subHistory(`Appliance Difference subscription appliance ${dataObject.totalAppliances} and house appliance Diff ${res.data.newAppliances} .`, dataObject, "Appliance Difference",  `${res.data.newAppliances}`)
        if (res.data.totalAppliances > 4 && dataObject.teleCallerId==="NetWay") {
          await extraAppliances(dataObject, res.data.totalAppliances - 4)
        } else if(res.data.totalAppliances>0 && dataObject.teleCallerId!=="NetWay"){
           await freeAppliance(dataObject, res.data.totalAppliances,false)
        }else {
          notify("All payment done")
          setDifferenceAppliancesText("All Payment Done Click on Final Installed")
        }
        if(res.data.totalAppliances>0 && dataObject.teleCallerId==="NetWay"){
         await freeAppliance(dataObject, res.data.totalAppliances,true)
        }
       }else{
        notifyFailed('Update Sirt failed')
       }
       setBtnText('Installation Done')
     })
     
    } else {
      notifyFailed(res.data.message)
      setBtnText("Installation Done")
    }
  }).catch(function (err) {
    console.log(err);
    setBtnText("Installation Done")
    notifyFailed(err.message)
  })
 
  setReload(!reload)
}
const updateSirt = async (sub, newApp) => {
  let oldApp = 0
  if (sub.changesInSubscription.length > 0) {
    oldApp = sub.changesInSubscription[sub.changesInSubscription.length - 1].oldAppliances
  }

  let totalApp = newApp + oldApp
let planFrequency=sub.planFrequency==="Monthly1"?"Monthly":sub.planFrequency
let securityCoastApplinace =100
let installation = sub.currentInstallation
 return await axios.post(`${serverUrl.u}/api/tele/updateSIRT`, {
    generatedHex: sub.generatedHex,
    planId: sub.planId,
    planFrequency: sub.planFrequency,
    totalAppliances: totalApp,
    security: Number(newApp) > 0 ? Number(newApp) * 100 : 0,
    installation: sub.currentInstallation,
  }).then(function (res) {

    if (res.data.success === true) {
      notify(res.data.message)
      if (sub.planFrequency !== planFrequency && totalApp !== sub.totalAppliances) {
        if (totalApp < sub.totalAppliances && compareFrequency[planFrequency] < compareFrequency[sub.planFrequency]) {
          subHistory(`Partial Subscription DowGrade Appliances & Frequency`, {
            ...sub, payload: {
              generatedHex: sub.generatedHex,
              planId: sub.planId,
              planFrequency: planFrequency,
              totalAppliances: totalApp,
              security: Number(newApp) > 0 ? Number(newApp) * Number(securityCoastApplinace) : 0,
              installation: installation,
            }
          }, "Partial Subscription UpGrade/DownGrade", "")
        } else if (totalApp > sub.totalAppliances && compareFrequency[planFrequency] > compareFrequency[sub.planFrequency]) {
          subHistory(`Partial Subscription UpGrage Appliances & Frequency`, {
            ...sub, payload: {
              generatedHex: sub.generatedHex,
              planId: sub.planId,
              planFrequency: planFrequency,
              totalAppliances: totalApp,
              security: Number(newApp) > 0 ? Number(newApp) * Number(securityCoastApplinace) : 0,
              installation: installation,
            }
          }, "Partial Subscription UpGrade/DownGrade", "")
        } else if (totalApp < sub.totalAppliances && compareFrequency[planFrequency] > compareFrequency[sub.planFrequency]) {
          subHistory(`Partial Subscription DownGrade Appliances & UpGrade Frequency`, {
            ...sub, payload: {
              generatedHex: sub.generatedHex,
              planId: sub.planId,
              planFrequency: planFrequency,
              totalAppliances: totalApp,
              security: Number(newApp) > 0 ? Number(newApp) * Number(securityCoastApplinace) : 0,
              installation: installation,
            }
          }, "Partial Subscription UpGrade/DownGrade", "")
        } else if (totalApp > sub.totalAppliances && compareFrequency[planFrequency] < compareFrequency[sub.planFrequency]) {
          subHistory(`Partial Subscription UpGrade Appliances & DownGrade Frequency`, {
            ...sub, payload: {
              generatedHex: sub.generatedHex,
              planId: sub.planId,
              planFrequency: planFrequency,
              totalAppliances: totalApp,
              security: Number(newApp) > 0 ? Number(newApp) * Number(securityCoastApplinace) : 0,
              installation: installation,
            }
          }, "Partial Subscription UpGrade/DownGrade", "")
        } else {
          subHistory(`Partial Subscription DownGrade/Upgrade Both`, {
            ...sub, payload: {
              generatedHex: sub.generatedHex,
              planId: sub.planId,
              planFrequency: planFrequency,
              totalAppliances: totalApp,
              security: Number(newApp) > 0 ? Number(newApp) * Number(securityCoastApplinace) : 0,
              installation: installation,
            }
          }, "Partial Subscription UpGrade/DownGrade", "")
        }

      } else if (totalApp !== sub.totalAppliances) {
        if (totalApp < sub.totalAppliances) {
          subHistory(`Partial Subscription DowGrade Appliances`, {
            ...sub, payload: {
              generatedHex: sub.generatedHex,
              planId: sub.planId,
              planFrequency: planFrequency,
              totalAppliances: totalApp,
              security: Number(newApp) > 0 ? Number(newApp) * Number(securityCoastApplinace) : 0,
              installation: installation,
            }
          }, "Partial Subscription UpGrade/DownGrade", "")
        } else {
          subHistory(`Partial Subscription UpGrage Appliances`, {
            ...sub, payload: {
              generatedHex: sub.generatedHex,
              planId: sub.planId,
              planFrequency: planFrequency,
              totalAppliances: totalApp,
              security: Number(newApp) > 0 ? Number(newApp) * Number(securityCoastApplinace) : 0,
              installation: installation,
            }
          }, "Partial Subscription UpGrade/DownGrade", "")
        }
      } else if (planFrequency !== sub.planFrequency) {
        if (compareFrequency[planFrequency] < compareFrequency[sub.planFrequency]) {
          subHistory(`Partial Subscription DownGrade in Frequency`, {
            ...sub, payload: {
              generatedHex: sub.generatedHex,
              planId: sub.planId,
              planFrequency: planFrequency,
              totalAppliances: totalApp,
              security: Number(newApp) > 0 ? Number(newApp) * Number(securityCoastApplinace) : 0,
              installation: installation,
            }
          }, "Partial Subscription UpGrade/DownGrade", "")
        } else if (compareFrequency[planFrequency] > compareFrequency[sub.planFrequency]) {
          subHistory(`Partial Subscription Upgrade in Frequency`, {
            ...sub, payload: {
              generatedHex: sub.generatedHex,
              planId: sub.planId,
              planFrequency: planFrequency,
              totalAppliances: totalApp,
              security: Number(newApp) > 0 ? Number(newApp) * Number(securityCoastApplinace) : 0,
              installation: installation,
            }
          }, "Partial Subscription UpGrade/DownGrade", "")
        }
      }
      return true
    } else {
      notify(res.data.message)
      return false
    }

  }).catch(function (err) {
    console.log(err);
    notify(err.response.data.message)
    return false
  })
}
const freeAppliance = async (dataObject, app,freeApp=true) => {
  let free = 0
  if (app > 4 && freeApp) {
    free = 4
  } else {
    free = app
  }
  console.log(dataObject.generatedHex, dataObject, "hex");
  await axios.post(`${serverUrl.u}/app/isp/registerISPSubscription`, {
    "generatedHex": dataObject.generatedHex,
    "teleCallerId": dataObject.teleCallerId,
    "totalAppliances": free,
    "installationDate": new Date(),
  }).then(async function (res) {
    console.log(res, "free");
    if (res.data.success === true) {
      await installationDoneByElectrician(dataObject, true, false)
      await removeElectricianFromSite(dataObject)
      notify(res.data.message)
    } else {
      notifyFailed(res.data.message)
    }
  }).catch(function (err) {
    console.log(err, "free error");
    notifyFailed("Error Occur")
  })
}

const extraAppliances = async (dataObject, app) => {
  await axios.post(`${serverUrl.u}/app/isp/registerSubscriptionForExtraAppliances`, {
    generatedHex: dataObject.generatedHex,
    totalAppliances: app,
  }).then(function (res) {
    console.log(res, "extra");
    if (res.data.success === true) {
      notify(res.data.message)
    } else {
      notifyFailed(res.data.message)
    }
  }).catch(function (err) {
    console.log(err, "extra error");
    notifyFailed("Error Occur")
  })
}

export const zohoLink = async (detail) => {
  return await axios.post(`${serverUrl.u}/api/partialSubscription/paymentLink`, detail).then(
    (res) => {
      console.log(res);
      if (res.data.success === true) {
        notify(res.data.message)
        return true
      } else {
        notifyFailed(res.data.message)
        return false
      }
    }
  ).catch((err) => {
    console.log(err);
    notifyFailed("Failed")
    return false
  })
}

export const daysDifference = (createdAt) => {
  // Create Date objects from the date strings
  var date1 = new Date(createdAt);
  var date2 = new Date();

  // Calculate the time difference in milliseconds
  var timeDiff = date2 - date1;

  // Convert the time difference to days
  var daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

  // console.log("Number of days between the two dates: " + daysDiff);
  if (daysDiff <= 5) {
    return ["green", daysDiff]
  } else if (daysDiff > 5 && daysDiff <= 10) {
    return ["yellow", daysDiff]
  } else {
    return ["red", daysDiff]
  }
}

export const recursiveAmountCalculate = async (planDurations, appliance) => {
  let fee = 0
  if (planDurations === "Monthly") {
    fee = (appliance * planCost.Monthly) * 30
  } else if (planDurations === "Quarterly") {
    fee = (appliance * planCost.Quarterly) * 90
  } else if (planDurations === "Semi Annualy") {
    fee = (appliance * planCost.SemiAnnualy) * 180
  } else if (planDurations === "Annualy") {
    fee = (appliance * planCost.Annualy) * 365
  } else if (planDurations === "Annualy-3") {
    fee = (appliance * planCost.Annualy) * 365
  } else if (planDurations === "Test (Weekly)") {
    fee = (appliance * planCost.TestWeekly) * 7
  } else if (planDurations === "Two Years") {
    fee = (appliance * planCost.TwoYears) * (365 * 2);
  } else if (planDurations === "Three Years") {
    fee = (appliance * planCost.ThreeYears) * (365 * 3);
  } else if (planDurations === "Quarterly2") {
    fee = (appliance * planCost.Quarterly2) * 90
  } else if (planDurations === "Semi Annualy2") {
    fee = (appliance * planCost.SemiAnnualy2) * 180
  } else if (planDurations === "Annualy2") {
    fee = (appliance * planCost.Annualy2) * 365
  }  else if (planDurations === "Three Years2") {
    fee = (appliance * planCost.ThreeYears2) * (365 * 3);
  } else if(appliance * planCost["Five Years"]){
    fee = (appliance * planCost["Five Years"]) * (365 * 5);
  }
  return fee
}
export const recursiveAmountCalculateCustom = async (cost,planDurations, appliance) => {
  console.log(cost,planDurations, appliance,"gggg");
  let fee = 0
  if (planDurations === "Monthly") {
    fee = (appliance * cost) * 30
  } else if (planDurations === "Quarterly") {
    fee = (appliance * cost) * 90
  } else if (planDurations === "Semi Annualy") {
    fee = (appliance * cost) * 180
  } else if (planDurations === "Annualy") {
    fee = (appliance * cost) * 365
  } else if (planDurations === "Annualy-3") {
    fee = (appliance * cost) * 365
  } else if (planDurations === "Test (Weekly)") {
    fee = (appliance * cost) * 7
  } else if (planDurations === "Two Years") {
    fee = (appliance * cost) * (365 * 2);
  } else if (planDurations === "Three Years") {
    fee = (appliance * cost) * (365 * 3);
  }
  return fee
}


export function getAllDates(data) {
  let currentDate = new Date(data.startDate);
  let endDate = new Date(data.endDate)
  const dates = [];

  while (currentDate <= endDate) {
    dates.push({ date: moment(currentDate).format('YYYY-MM-DD'), description: data.description });
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
}



export function getFirstandLastDate(month) {
  let date = new Date(month);
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  console.log("First day=" + firstDay)
  console.log("Last day = " + lastDay);
  return {
    firstDay, lastDay
  }
}


export const getAllDevices = async (houseId) => {
  let deviceIds = []

  await axios.post(`${serverUrl.u}/api/div/getAllDevices`,
    {
      "houseId": houseId,
    }).then(function (res) {
      console.log(res);
      for (let device of Object.keys(res.data.data)) {
        //  console.log(res.data.data[device]);
        for (let value of Object.keys(res.data.data[device])) {
          console.log(value, res.data.data[device][value]);
          deviceIds = [...deviceIds, ...res.data.data[device][value]]
        }
      }

    }).catch(function (err) {
      console.log(err);
    })
  return deviceIds
}

export const changeDate = async (hex,installationDate,loginName) => {
  await axios.post(`${serverUrl.u}/api/tele/changeInstallationDate`, {
    generatedHex: hex,
    InstallationDate: installationDate,
    loggingPerson: loginName,
  }).then(function (response) {
    if (response.data.success === true) {
      notify(response.data.message)
    } else {
      notify(response.data.message)
    }
  }).catch(function (error) {
    console.log(error);
    notify(error.response.data.message)
  })
}

export const sendToDispatch = async(id)=>{
  await axios.post(`${serverUrl.u}/api/tele/updateDispatchStatus`, {
    subId: id,
    status: "ready to dispatch",
    alisteUserId:store.getState().sar.data.user._id
  }).then(function (response) {
    if (response.data.success === true) {
      notify("Dispatched Successfully")
     
    } else {
      notify(response.data.message)

    }
  })
    .catch(function (error) {
      
      notifyFailed(error.response.data.error)
    })
}

export const assignElectrician =async(hex,electrician)=>{
  await axios.post(`${serverUrl.u}/api/dispatch/assignElectricianOnInstallationPage`,{
    generatedHex:hex,
    electrician:electrician 
  }).then(function (response) {
  if(response.data.success===true){
    notify(response.data.message)
  }else{
    notify("Error Occured")
  }
}).catch(function (error) {
  
  notify(error.response.data.message)
})
}

export async function getInvoicesViaPhoneNumber(phone){
return await axios.post(`${serverUrl.u}/api/tele/getAllOverdueInvoice`, {
    phone: phone
  }).then(function (res) {
    console.log(res);
    if (res.data.success === true) {
      notify(res.data.message)
      return res.data.finalInvoice
    }else{
      notify(res.data.message)
      return []
    }
  }).catch(function (err) {
    console.log(err);
    notify(err.response.data.message)
    if(err.response.data.message==="Invoice not found"){
      notify("Invoice not found")
      
    }
    return []
  })
}

export const updateGstDetailsInInvoices = async(invoiceNumber,gstName,gstNumber)=>{
  return await axios.post(`${serverUrl.u}/app/tele/updateGSTDetailsInInvoice`,{
    invoiceNumber,gstName,gstNumber
  }).then(function (res) {
      console.log(res);
      if(res.data.success){
        notify(res.data.message)
        return true
      }
      else{
        notifyFailed(res.data.message)
        return false
      }
  }).catch(function (err) {
      notifyFailed("Failed to Update Gst Details")
      return false
  })
}

export const changeSubscriptionStage = async(hex,stage) => {
 await axios
    .post(`${serverUrl.u}/api/tele/changeSubscriptionStage`, {
      generatedHex: hex,
      subscriptionStage: stage,
    })
    .then(function (res) {
      if (res.status === 200) {
        notify(res.data.message);



      } else {
        notify(res.data.message);
      }
      console.log(res);
    })
}

export const timeFormate = (date) => {

  // Get the current date and time
  let now = new Date(date);

  // Format the date as "YYYY-MM-DD" (required by datetime-local input)
  let year = now.getFullYear();
  let month = (now.getMonth() + 1).toString().padStart(2, '0');
  let day = now.getDate().toString().padStart(2, '0');
  let formattedDate = `${year}-${month}-${day}`;

  // Format the time as "HH:MM" (required by datetime-local input)
  let hours = now.getHours().toString().padStart(2, '0');
  let minutes = now.getMinutes().toString().padStart(2, '0');
  let formattedTime = `${hours}:${minutes}`;

  // Set the default value of the input field
  console.log(`${formattedDate}T${formattedTime}`, "rrrrrrrrrrrrrr");
  return `${formattedDate}`;

}