import React, { useState } from 'react';
import { InvoiceGenerate } from '../Function/InvoieDownloadFun';
import JSZip from 'jszip';
import { Box, Modal } from '@mui/material';

const InvoiceView = ({ e, ele, notify }) => {
  const [url, setUrl] = useState(false);
  const [loading, setLoading] = useState(false);
  const InvoiceGenerated = async () => {
    setLoading(true);
    try {
      let res = await InvoiceGenerate(ele, e, notify);

      if (res[0] === 'then') {
        if (res[1].status === 200) {
          setUrl(true)
          if (!e.is_UpSell) {
            const zip = new JSZip();
            const zipContent = await zip.loadAsync(res[1].data);
            for (let file in zipContent?.files) {
              // Extract the PDF file
              const pdfFile = zipContent?.files[file];
              if (pdfFile) {
                const pdfBlob = await pdfFile.async('blob');
                // FileSaver.saveAs(pdfBlob, `${e.name} - invoice.pdf`);
              } else {
                console.error('PDF file not found in the ZIP archive.');
              }
            }
          } else {
            // Create a Blob from the response data (PDF)
            const blob = new Blob([res[1].data], { type: 'application/pdf' });

            // Use file-saver to download the file
            // FileSaver.saveAs(blob, `${e.name} - invoice.pdf`);
          }
        } else {
          setUrl(false)
          notify('Failed');
        }
      } else {
        setUrl(false)
        notify('Failed');
      }
      setLoading(false);
    } catch (error) {
      setUrl(false)
      setLoading(false);
    }
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    height:"80%",
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: "12px"
};
  return (
    <>
      <button
        className='btn btn-primary'
        disabled={loading}
        onClick={() => InvoiceGenerated()}
      >
        {loading ? 'Looading...' : 'Invoice View'}
      </button>
   
     <Modal
     open={url}
     onClose={()=>setUrl(false)}
     aria-labelledby="modal-modal-title"
     aria-describedby="modal-modal-description"
 >
  <Box style={style}>
        <div
              style={{
                position: 'absolute',
                right: '17%',
                top:"1.3%",
                cursor: 'pointer',
               
                borderRadius: '100%',
                width: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => setUrl('')}
            >
              <span style={{ fontSize: '2px',color:'white' }}>X</span>
            </div>
         <iframe src="https://subscriptioncloud.alistetechnologies.com/invoicePdf/recursiveInvoice.pdf" width="100%" height="100%"></iframe>
         </Box>
      
       </Modal>
 
    
    </>
  );
};

export default InvoiceView;
