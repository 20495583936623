export const dummy = [
  [
    {
      roomName: "Custom",
      roomId: 0,
      switchboards: [
        {
          switchboardNumber: "1",
          appliances: {
            fans: 0,
            light: 0,
            ac: 0,
            geyser: 0,
            tv: 0,
            socket: 0,
            other: 0,
            ir: 0,
          },
        },
      ],
    },
  ],
  [
    {
      roomName: "1 Room",
      roomId: 0,
      switchboards: [
        {
          switchboardNumber: "1",
          appliances: {
            fans: 1,
            light: 1,
            ac: 1,
            geyser: 1,
            tv: 0,
            socket: 0,
            other: 0,
            ir: 0,
          },
        },
      ],
    },
  ],
  [
    {
      roomName: "1 BHK",
      roomId: 0,
      switchboards: [
        {
          switchboardNumber: "1",
          appliances: {
            fans: 1,
            light: 1,
            ac: 1,
            geyser: 1,
            tv: 4,
            socket: 0,
            other: 0,
            ir: 0,
          },
        },
      ],
    },
  ],
  [
    {
      roomName: "2 BHK",
      roomId: 0,
      switchboards: [
        {
          switchboardNumber: "1",
          appliances: {
            fans: 1,
            light: 1,
            ac: 1,
            geyser: 1,
            tv: 8,
            socket: 0,
            other: 0,
            ir: 0,
          },
        },
      ],
    },
  ],
  [
    {
      roomName: "3 BHK",
      roomId: 0,
      switchboards: [
        {
          switchboardNumber: "1",
          appliances: {
            fans: 1,
            light: 1,
            ac: 1,
            geyser: 1,
            tv: 0,
            socket: 14,
            other: 0,
            ir: 0,
          },
        },
      ],
    },
  ],
  [
    {
      roomName: "4 BHK",
      roomId: 0,
      switchboards: [
        {
          switchboardNumber: "1",
          appliances: {
            fans: 1,
            light: 1,
            ac: 1,
            geyser: 1,
            tv: 0,
            socket: 18,
            other: 0,
            ir: 0,
          },
        },
      ],
    },
  ],
  [
    {
      roomName: "5 BHK",
      roomId: 0,
      switchboards: [
        {
          switchboardNumber: "1",
          appliances: {
            fans: 1,
            light: 1,
            ac: 1,
            geyser: 1,
            tv: 0,
            socket: 22,
            other: 0,
            ir: 0,
          },
        },
      ],
    },
  ],
  [
    {
      roomName: "6 BHK",
      roomId: 0,
      switchboards: [
        {
          switchboardNumber: "1",
          appliances: {
            fans: 1,
            light: 1,
            ac: 1,
            geyser: 1,
            tv: 0,
            socket: 26,
            other: 0,
            ir: 0,
          },
        },
      ],
    },
  ],
];

export const SeurityInstallion = [
  {
    planName: "1Room",
    security: 400,
    Installion: 1000,
    quantity: 4,
  },
  {
    planName: "1BHK",
    security: 800,
    Installion: 1000,
    quantity: 8,
  },
  {
    planName: "2BHK",
    security: 1200,
    Installion: 1100,
    quantity: 12,
  },
  {
    planName: "3BHK",
    security: 1800,
    Installion: 1400,
    quantity: 18,
  },
  {
    planName: "4BHK",
    security: 2200,
    Installion: 1600,
    quantity: 22,
  },
  {
    planName: "5BHK",
    security: 2600,
    Installion: 1800,
    quantity: 26,
  },
  {
    planName: "6BHK",
    security: 3000,
    Installion: 2000,
    quantity: 30,
  },
];
export const user_roles = {
  SuperAdmin: "Super Admin",
  Accounts: "Accounts",
  Admin: "Admin",
  Tester: "Tester",
  HardwareTester: "Hardware Tester",
  OnlineTester: "Online Tester",
  FanModuleTester: "Fan Module Tester",
  Sales: "Sales",
  SalesAdmin: "SalesAdmin",
  Grievance: "Grievance",
  Telecaller: "Telecaller",
  SmartMeter: "SmartMeter",
  Dispatch: "Dispatch",
  LeadHandler: "LeadHandler",
  Assembly: "Assembly",
  Isp: "ISP",
  Installation: "Installation",
  ExternalTeleCaller: "External TeleCaller",
  AssignElectrician: "Assign Electricians",
  Any: "*",
};
export const plan = [
  { text: "1 Room" },
  { text: "1 BHK" },
  { text: "2 BHK" },
  { text: "3 BHK" },
  { text: "4 BHK" },
  { text: "5 BHK" },
  { text: "6 BHK" },
  { text: "Custom" },
];

export const planDuration = [
  { value: "Monthly", id: 1 },
  { value: "Quarterly", id: 2 },
  { value: "Semi Annualy", id: 3 },
  { value: "Annualy", id: 4 },
  { value: "Test (Weekly)", id: 0 },
  { value: "Two Years", id: 5 },
  { value: "Three Years", id: 6 },
  { value: "Annualy-3", id: 7 },
  { value: "Quarterly2", id: 8 },
  { value: "Semi Annualy2", id: 9 },
  { value: "Annualy2", id: 10 },
  { value: "Three Years2", id: 11 },
  { value: "Five Years", id: 12 },
];
export const ispPlanDuration = [
  { value: "Monthly1", id: 1, rate: 30 },
  { value: "Quarterly", id: 2, rate: 90 },
  { value: "Semi Annualy", id: 3, rate: 180 },
  { value: "Annualy", id: 4, rate: 365 },
];
export const planCost = {
  Monthly: 2,
  Quarterly: 1,
  SemiAnnualy: 1,
  Annualy: 1,
  TestWeekly: 1,
  TwoYears: 1,
  ThreeYears: 1,
  Quarterly2: 2,
  SemiAnnualy2: 2,
  Annualy2: 2,
  ThreeYears2: 1,
  "Five Years": 1,
};
export const compareFrequency = {
  Monthly: 1,
  Quarterly: 2,
  SemiAnnualy: 3,
  Annualy: 4,
  TestWeekly: 0,
  TwoYears: 5,
  ThreeYears: 6,
  "Annualy-3": 7,
  Quarterly2: 8,
  SemiAnnualy2: 9,
  Annualy2: 10,
  ThreeYears2: 11,
  "Five Years": 12,
};

export const PartialPaymentModes = [
  { value: "razorpay" },
  { value: "BankTransfer" },
  { value: "upi" },
];
export const PaymentModes = [{ value: "BankTransfer" }, { value: "upi" }];

export const state = [
  { value: "DELHI" },
  { value: "NEW DELHI" },
  { value: "NOIDA" },
  { value: "GURGAON" },
  { value: "FARIDABAD" },
  { value: "GREATER NOIDA" },
  { value: "GHAZIABAD" },
];

export const InvoiceStatus = [
  { value: "paid" },
  { value: "overdue" },
  { value: "sent" },
];

export const finalSelectDeviceUpdate = [{ value: "sync" }, { value: "nova" }];

export const deleteAndDropCategory = [
  { value: "Payment - Not Expected" },
  { value: "Refund" },
  { value: "WiFi" },
  { value: "Hardware" },
  { value: "NpDnui" },
  { value: "Testing" },
  { value: "House Shifting" },
  { value: "Light on/off automatically" },
  { value: "Hang" },
  { value: "Wifi issue" },
  { value: "Not using" },
  { value: "Power" },
  { value: "Other" },
];

export const department = [
  { value: "Tech" },
  { value: "General" },
  { value: "Dispatch" },
  { value: "Sales" },
  { value: "Drop" },
];
export const grevianceCategrory = [
  { value: "Hanging" },
  { value: "Wifi - online offline" },
  { value: "Wifi - cred shift" },
  { value: "Wifi - router issue" },
  { value: "Device burn" },
  { value: "Device Shifting" },
  { value: "Device restart" },
  { value: "NpDnui" },
  { value: "FAQ inquiry" },
  { value: "Partial UnInstallation" },
  { value: "Problem in Motion Senser" },
  { value: "Need more appliance" },
  { value: "Fan - on off automatically" },
  { value: "Fan - misbehaving" },
  { value: "Un- Instillation" },
  { value: "Miscellaneous" },
  { value: "Switch not working" },
  { value: "Wifi Isse" },
  { value: "Billing related problems" },
  { value: "Light on/off automatically" },
  { value: "Power issue" },
  { value: "House deactivate" },
  { value: "Fan issue" },
  { value: "Ir blaster" },
  { value: "Electrician - wrong wiring" },
  { value: "Electrican - half installation" },
  { value: "Voice assistance issue" },
  { value: "App Crasing (Android)" },
  { value: "App Crasing (IOS)" },
  { value: "Technical Issue" },
  { value: "Server Issue" },
  { value: "Dropped" },
  { value: "Installation Done By Electrician" },
  { value: "Meter offline" },
  { value: "Meter restarting" },
  { value: "Meter not working" },
  { value: "Meter Output bypass" },
  { value: "Meter current sensor issue" },
  { value: "Meter RTC issue" },
  { value: "Meter sort" },
  { value: "Reason for disable" },
  { value: "All okay" },
];

export const ElectricianIncentiveZone = [
  { value: "GURGAON" },
  { value: "WEST DELHI" },
  { value: "NORTH DELHI" },
  { value: "SOUTH DELHI" },
  { value: "FARDIABAD" },
  { value: "GHAZIABAD" },
  { value: "EAST DELHI" },
  { value: "GREATER NOIDA" },
  { value: "NOIDA" },
  { value: "MUMBAI" },
  { value: "OUT OF NCR" },
  { value: "BENGALURU" },
  { value: "B2B" },
];
export const takePaymentOnZones = ["OUT OF NCR", "B2B"];

export const indianStates = [
  { value: "Andaman and Nicobar Islands" },
  { value: "Andhra Pradesh" },
  { value: "Arunachal Pradesh" },
  { value: "Assam" },
  { value: "Bihar" },
  { value: "Chandigarh" },
  { value: "Chhattisgarh" },
  { value: "Dadra and Nagar Haveli and Daman and Diu" },
  { value: "Delhi" },
  { value: "Goa" },
  { value: "Gujarat" },
  { value: "Haryana" },
  { value: "Himachal Pradesh" },
  { value: "Jharkhand" },
  { value: "Karnataka" },
  { value: "Kerala" },
  { value: "Ladakh" },
  { value: "Lakshadweep" },
  { value: "Madhya Pradesh" },
  { value: "Maharashtra" },
  { value: "Manipur" },
  { value: "Meghalaya" },
  { value: "Mizoram" },
  { value: "Nagaland" },
  { value: "Odisha" },
  { value: "Puducherry" },
  { value: "Punjab" },
  { value: "Rajasthan" },
  { value: "Sikkim" },
  { value: "Tamil Nadu" },
  { value: "Telangana" },
  { value: "Tripura" },
  { value: "Uttar Pradesh" },
  { value: "Uttarakhand" },
  { value: "West Bengal" },
];

export const typeOFClient = [
  { value: "subscription" },
  { value: "capex" },
  { value: "sitevisit" },
  { value: "FreeClient" },
  { value: "smartMeter-Visit" },
];

export const orderTyepes = [
  { value: "subscription" },
  { value: "capex" },
  { value: "upSell" },
  { value: "sitevisit" },
  { value: "FreeClient" },
  { value: "Exclude FreeClient" },
  { value: "All" },
  { value: "smartMeter-Installation" },
];
export const interstedorNotClient = [
  { value: "", name: "All" },
  { value: true, name: "Intersted Clients" },
  { value: false, name: "Not Intersted Clients" },
];
export const monthFilter = [
  { value: "", name: "All" },
  { value: 0, name: "Current Month" },
  { value: -1, name: "Previous Month" },
];
export const readyConstrcuction = [
  { value: "", name: "All" },
  { value: "Interested", name: "Interested" },
  { value: "Not Interested", name: "Not Interested" },
  { value: "construction", name: "Construction" },
  { value: "noteSet", name: "Not Set Site Type" },
];

export const subscriptionStages = [
  { value: "all", name: "All" },
  { value: "address&layout", name: "Address & Layout" },
  { value: "installation", name: "Installation" },
  { value: "installed & complete", name: "Installed & Complete" },
];

export const StagesSubscription = [
  { value: "address&layout", name: "Address & Layout" },
  { value: "ready to install", name: "Installation Page" },
  { value: "installed & complete", name: "Installed & Complete" },
  { value: "installed", name: "Installed" },
];

export const GrievanceHandler = ["Heena", "Vikas", "Sidharth", "Tanu"];
