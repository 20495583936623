import { DeleteForever } from "@mui/icons-material";
import { Box, Button, Grid, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { registerNewClient } from "../../../component/Client registation/Api";
import { INDIAN_STATES, INDIANSTATES } from "../../../constants";
import { apiRequest } from "../../../utils";
import { serverUrl } from "../../../component/ApiUrl";
import { notify, notifyFailed } from "../../../component/Common/Notification";
import ReactSelect from "react-select";

export default function RegisterCustomer({
  setTab,
  customerData,
  setCustomerData,
}) {
  const [loading, setLoading] = useState(false);

  const [clientsData, setClientsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const clientResponse = await apiRequest({
        url: `${serverUrl.smartMeter}/client/fetch`,
        method: "GET",
      });

      if (!clientResponse.success) {
        notifyFailed("Failed to fetch Client Data!!");
        return;
      }

      setClientsData(clientResponse.data?.data);
    };
    fetchData();
  }, []);

  let type = "SUBMIT";
  let id;

  let defaultValue = {
    first_name: "",
    last_name: "",
    legalName: "",
    type: "B2C",
    phoneNumbers: [{ phone: "" }],
    emails: [{ email: "" }],
    gstNumber: "",
    clientName: "",
    addresses: [
      {
        address: "",
        city: "",
        state: "",
        pinCode: "",
        gstNumber: "",
        country: "India",
      },
    ],
  };

  if (Object.keys(customerData).length) {
    type = "UPDATE";
    id = customerData._id;
    for (let key of Object.keys(defaultValue)) {
      if (key === "emails") {
        defaultValue[key] = customerData.emails.map((e) => ({ email: e }));
        continue;
      }

      if (key === "phoneNumbers") {
        defaultValue[key] = customerData.phoneNumbers.map((ph) => {
          return {
            phone: ph,
          };
        });
        continue;
      }
      defaultValue[key] = customerData[key];
    }
  }
  const {
    register,
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValue,
  });

  const {
    fields: phoneFields,
    append: appendPhone,
    remove: removePhone,
  } = useFieldArray({
    control,
    name: "phoneNumbers",
  });

  const {
    fields: emailField,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: "emails",
  });

  const {
    fields: addressField,
    append: appendAddress,
    remove: removeAddress,
  } = useFieldArray({
    control,
    name: "addresses",
  });

  async function onSubmit(data) {
    const finalData = data;

    finalData.phoneNumbers = data.phoneNumbers.map((d) => d.phone);
    finalData.emails = data.emails.map((d) => d.email);

    console.log("data", data);

    setLoading(true);
    // const response = await registerNewClient(finalData);

    if (finalData.legalName.length < 5) {
      notifyFailed("Legal Name cannot be less than 5 characters!!");
      return;
    }

    const response = await apiRequest({
      url: `${serverUrl.u}/v3/customer/create`,
      data: finalData,
      method: "POST",
    });

    if (!response.success) {
      notifyFailed(response.message);

      return;
    }

    if (response.success) {
      setLoading(false);
      setTab(0);
    }

    setLoading(false);
  }

  // UPDATE CUSTOMER
  async function onUpdate(data) {
    const finalData = { id, ...data };

    finalData.phoneNumbers = data.phoneNumbers.map((d) => d.phone);
    finalData.emails = data.emails.map((d) => d.email);

    const response = await apiRequest({
      url: `${serverUrl.u}/v3/customer/update`,
      method: "POST",
      data: finalData,
    });

    if (response.success) {
      notify("User Updated <3");
      setLoading(false);
      setCustomerData({});
      setTab(0);
      return;
    }

    notifyFailed(response.message);
  }
  return (
    <>
      <h3 style={{ fontSize: "2rem", fontWeight: "600", marginBottom: "1rem" }}>
        {type === "SUBMIT" ? "Register Client" : "Update Client"}
      </h3>

      <Box
        component="form"
        onSubmit={
          type === "SUBMIT" ? handleSubmit(onSubmit) : handleSubmit(onUpdate)
        }
      >
        <Grid container spacing={2}>
          {/* First Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="First Name"
              fullWidth
              {...register("first_name", {
                required: "First Name is required",
              })}
              error={errors?.first_name}
              helperText={errors?.first_name?.message}
            />
          </Grid>

          {/* Last Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last Name"
              fullWidth
              {...register("last_name")}
              error={errors?.last_name}
              helperText={errors?.last_name?.message}
            />
          </Grid>

          {/* Legal Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Legal Name"
              fullWidth
              {...register("legalName", { required: "Legal Name is required" })}
              error={errors?.legalName}
              helperText={errors?.legalName?.message}
            />
          </Grid>

          {/* GST Number */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="GST Number"
              fullWidth
              {...register("gstNumber")}
            />
          </Grid>

          {/* Type */}
          <Grid item xs={12} sm={6}>
            <Controller
              name="type"
              control={control}
              className="form-control"
              render={({ field }) => (
                <select {...field} className="form-control">
                  <option value="B2C">B2C</option>
                  <option value="B2B">B2B</option>
                </select>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="clientName"
              control={control}
              className="form-control"
              render={({ field }) => (
                <select {...field} className="form-control">
                  <option value="">Select Client</option>
                  {clientsData.map((client) => (
                    <option value={client.name} key={client.name}>
                      {client.name}
                    </option>
                  ))}
                </select>
              )}
            />
          </Grid>

          {/* PhoneNumbers */}
          <Grid item xs={12}>
            <Box mb={2}>Phone Numbers</Box>
            <Grid container spacing={2}>
              {phoneFields.map((field, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  key={field.id}
                  sx={{ position: "relative" }}
                >
                  <TextField
                    label={`PhoneNumber ${index + 1}`}
                    fullWidth
                    {...register(`phoneNumbers.${index}.phone`, {
                      required: "Phone Number is required",
                    })}
                    error={
                      errors.phoneNumbers && errors.phoneNumbers[index]?.phone
                    }
                    helperText={
                      errors.phoneNumbers &&
                      errors.phoneNumbers[index]?.phone?.message
                    }
                  />

                  <DeleteForever
                    onClick={() => removePhone(index)}
                    className="absolute"
                    style={{ right: 0, zIndex: 1, color: "#d32f2f" }}
                  />
                </Grid>
              ))}
            </Grid>
            <Box sx={{ mt: "auto", p: 1 }}>
              <Button
                variant="contained"
                onClick={() => appendPhone({ phone: "" })}
              >
                Add PhoneNumber
              </Button>
            </Box>
          </Grid>

          {/* Emails */}
          <Grid item xs={12}>
            <Box mb={2}>Emails</Box>
            <Grid container spacing={2}>
              {emailField.map((field, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ position: "relative" }}
                  key={field.id}
                >
                  <TextField
                    label={`Email ${index + 1}`}
                    type="email"
                    fullWidth
                    {...register(`emails.${index}.email`)}
                    error={errors?.emails && errors?.emails[index]?.email}
                    helperText={
                      errors?.emails && errors?.emails[index]?.email?.message
                    }
                  />
                  <DeleteForever
                    onClick={() => removeEmail(index)}
                    className="absolute"
                    style={{ right: 0, zIndex: 1, color: "#d32f2f" }}
                  />
                </Grid>
              ))}
            </Grid>
            <Box sx={{ mt: "auto", p: 1 }}>
              <Button
                variant="contained"
                onClick={() => appendEmail({ email: "" })}
              >
                Add Email
              </Button>
            </Box>
          </Grid>

          {/* Addresses */}
          <Grid item xs={12}>
            <Box mb={2}>Addresses</Box>
            {addressField.length > 0 ? (
              addressField.map((field, index) => (
                <Box
                  key={field.id}
                  mb={2}
                  p={2}
                  border={1}
                  borderColor="grey.300"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="Address"
                        fullWidth
                        {...register(`addresses.${index}.address`, {
                          required: "Address is required",
                        })}
                        error={
                          errors?.addresses && errors?.addresses[index]?.address
                        }
                        helperText={
                          errors?.addresses &&
                          errors?.addresses[index]?.address?.message
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        value={"India"}
                        InputProps={{ readOnly: true }}
                        {...register(`addresses.${index}.country`)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name={`addresses.${index}.state`}
                        control={control}
                        defaultValue={null}
                        rules={{ required: "State is required" }}
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            options={INDIANSTATES}
                            isClearable
                            placeholder="Select a state"
                            onChange={(selected) => {
                              field.onChange(selected.value);
                            }}
                            value={INDIANSTATES.find(
                              (state) =>
                                state.value.toLowerCase() ===
                                  getValues(
                                    `addresses.${index}.state`
                                  )?.toLowerCase() || ""
                            )}
                          />
                        )}
                      />
                      {/* <Controller
                        name={`addresses.${index}.state`}
                        control={control}
                        render={({ field }) => (
                          <Controller
                            name={`addresses.${index}.state`}
                            control={control}
                            render={({ field }) => (
                              <select {...field} className="form-control">
                                {Object.keys(INDIAN_STATES).map((state) => (
                                  <option value={state} key={state}>
                                    {state}
                                  </option>
                                ))}
                              </select>
                            )}
                          />
                        )}
                      /> */}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="city"
                        fullWidth
                        {...register(`addresses.${index}.city`, {
                          required: "City is required",
                        })}
                        error={
                          errors?.addresses && errors?.addresses[index]?.city
                        }
                        helperText={
                          errors?.addresses &&
                          errors?.addresses[index]?.city?.message
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Pin Code"
                        fullWidth
                        type="number"
                        {...register(`addresses.${index}.pinCode`, {
                          required: "Pin Code is required",
                        })}
                        error={
                          errors?.addresses && errors?.addresses[index]?.pinCode
                        }
                        helperText={
                          errors?.addresses &&
                          errors?.addresses[index]?.pinCode?.message
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="GST Number"
                        fullWidth
                        {...register(`addresses.${index}.gstNumber`)}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => removeAddress(index)}
                    sx={{ mt: 2 }}
                  >
                    Remove
                  </Button>
                </Box>
              ))
            ) : (
              <Box>No addresses added</Box>
            )}
            <Box sx={{ mt: "auto", p: 1 }}>
              <Button
                variant="contained"
                onClick={() =>
                  appendAddress({
                    address: "",
                    city: "",
                    state: "",
                    pinCode: "",
                    country: "India",
                  })
                }
              >
                Add Address
              </Button>
            </Box>
          </Grid>
        </Grid>

        {type === "SUBMIT" ? (
          <Button variant="contained" type="submit" fullWidth>
            Submit
          </Button>
        ) : (
          <Button variant="contained" type="submit" fullWidth>
            Update
          </Button>
        )}
      </Box>
    </>
  );
}
